import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { alertActions, menuActions, userActions } from "../../actions";
import { apiConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import TermsAndConditionPopup from "../Common/Popup/TermsAndConditionPopup";

const publicIp = require("public-ip");
var speakeasy = require("speakeasy");

const Registration = (props) => {
  const rejectedDomain = ["mailinator.com"];

  const dispatch = useDispatch();
  const [alertRes, setAlertRes] = useState("");
  const [check, setCheck] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [siteConstants, setSiteConstants] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    countryCode: "",
    contact: "",
    username: "",
    country: "",
    email: "",
    password: "",
    confirmPassword: "",
    sponsor: "",
    role_type: "user",
    terms_condition: false,
  });
  const [contactCode, setContactCode] = useState("");
  const [totalUser, setTotalUser] = useState(0);
  const [errState, setErrState] = useState({});
  const [adminReferal, setAdminReferal] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [codeList, setCodeList] = useState([]);
  const [link, setLink] = useState({});
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [displayCheck, setDisplayCheck] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [termsPopup, setTermsPopup] = useState(false);
  const [viewPassword, setViewPassword] = useState({
    userpassword: false,
    confirmPassword: false,
  });

  const {
    alert,
    language,
    resendEmail,
    getAdminReferal,
    getCountryList,
    checkReferLink,
    getSetting,
    getCount,
    getSignUpDetails,
  } = useSelector((state) => {
    return {
      alert: state.alert,
      language: state.language,
      resendEmail: state.resendEmail,
      getAdminReferal: state.getAdminReferal,
      getCountryList: state.getCountryList,
      checkReferLink: state.checkReferLink,
      getSetting: state.getSetting,
      getCount: state.getCount,
      getSignUpDetails: state.userSignup,
    };
  });

  const colourStyles = {
    option: ({ isDisabled }) => {
      return { cursor: isDisabled ? "not-allowed" : "default" };
    },
    control: (base) => ({
      ...base,
      // height: 'calc(1.5em + .75rem + 2px)',
      height: 30,
      minHeight: 30,
    }),
  };
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setIsLoading(false);
  };

  // this is for register .
  const handleShow = () => {
    if (handleValidation()) {
      setFlag(true);
      let obj = {
        email: formData.email,
        username: formData.username,
        contact: formData.countryCode + " " + formData.contact,
        sponsor: formData.sponsor,
      };
      setIsLoading(true);
      // dispatch(userActions.sendEmail(apiConstants.VERIFY_REGISTER_EMAIL, obj));
    }
  };

  const handleKeydown = (value) => {
    if (value.length >= 1) {
      setDisplayCheck(false);
    } else if (value.length == 0) {
      setDisplayCheck(true);
    } else {
      setDisplayCheck(true);
    }
  };

  const selectedTermsRes = (val) => {
    if (val === "Yes") {
      setTermsPopup(false);
    } else {
      setTermsPopup(false);
    }
  };

  const Toggle = (name) => {
    var temp = document.getElementById(name);
    if (temp.type === "password") {
      temp.type = "text";
      setViewPassword({ ...viewPassword, [name]: true });
    } else {
      temp.type = "password";
      setViewPassword({ ...viewPassword, [name]: false });
    }
  };

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language]);

  useEffect(() => {
    if (resendEmail.isMailSend && !resendEmail.isLoading) {
      if (resendEmail.mailSend.data !== undefined) {
        if (flag) {
          setShow(true);
          setIsSubmit(true);
          localStorage.setItem("key", resendEmail.mailSend.data.data.key);
        }
      }
    }
  }, [resendEmail]);

  useEffect(() => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("planType");
    // const { location } = props;
    // let params = queryString.parse(location.search)

    let path = props.location.pathname;
    const splitData = path.split("/register/");

    // const referenceLink = params.ref;
    const referenceLink = splitData[1];
    if (referenceLink !== null && referenceLink !== undefined) {
      let obj = { refer_link: referenceLink };
      dispatch(userActions.checkReferLink(apiConstants.CHECK_REFER_LINK, obj));
    }
    dispatch(alertActions.clear());
    dispatch(userActions.getAdminReferal(apiConstants.GET_ADMIN_REFERAL));
    dispatch(userActions.getCount(apiConstants.GET_REGISTERED_USER));
    dispatch(userActions.getCountryList(apiConstants.GET_COUNTRY_CODE));
    dispatch(menuActions.getMenu(apiConstants.GET_MENU));

    setTimeout(() => {
      dispatch(userActions.getSetting(apiConstants.GET_SETTINGS));
    }, 300);
    return () => {
      setAlertRes("");
    };
  }, []);

  useEffect(() => {
    if (Object.keys(getCountryList).length > 0) {
      if (getCountryList.getCountryList !== undefined) {
        const { data } = getCountryList.getCountryList;
        const list = data.data;
        let arr = [];
        let arr1 = [];
        list.map((item) => {
          arr.push({ value: item.code, label: item.code });
        });
        list.map((item) => {
          arr1.push({
            value: item.country,
            label: item.country,
            code: item.code,
          });
        });
        setCodeList(arr);
        setCountryList(arr1);
      }
    }
    if (Object.keys(checkReferLink).length > 0) {
      if (checkReferLink.isChecked) {
        const result = checkReferLink.checkedUser.data.data.email;
        setFormData({ ...formData, sponsor: result });
        setReadOnly(true);
      }
    }
    if (Object.keys(getSetting).length > 0) {
      if (getSetting.isGetSetting) {
        if (getSetting.getSetting.data) {
          const { data } = getSetting.getSetting;

          setLink({
            facebookLink:
              data.data.facebookLink !== null && data.data.facebookLink !== ""
                ? data.data.facebookLink
                : "#",
            telegramLink:
              data.data.telegramLink !== null && data.data.telegramLink !== ""
                ? data.data.telegramLink
                : "#",
            instagramLink:
              data.data.instagramLink !== null && data.data.instagramLink !== ""
                ? data.data.instagramLink
                : "#",
            linkedInLink:
              data.data.linkedInLink !== null && data.data.linkedInLink !== ""
                ? data.data.linkedInLink
                : "#",
            twitterLink:
              data.data.twitterLink !== null && data.data.twitterLink !== ""
                ? data.data.twitterLink
                : "#",
            tiktokLink:
              data.data.tiktokLink !== null && data.data.tiktokLink !== ""
                ? data.data.tiktokLink
                : "#",
            youtubeLink:
              data.data.youtubeLink !== null && data.data.youtubeLink !== ""
                ? data.data.youtubeLink
                : "",
            registerLink:
              data.data.registerLink !== null && data.data.registerLink !== ""
                ? data.data.registerLink
                : "",
            videoLink:
              data.data.videoLink !== null && data.data.videoLink !== ""
                ? data.data.videoLink
                : "",
            fundLink:
              data.data.fundLink !== null && data.data.fundLink !== ""
                ? data.data.fundLink
                : "",
            ppt:
              data.data.ppt !== null &&
                data.data.ppt !== "" &&
                data.data.ppt.length > 0
                ? data.data.ppt
                : "#",
            pdf:
              data.data.pdf !== null &&
                data.data.ppt !== "" &&
                data.data.ppt.length > 0
                ? data.data.pdf
                : "#",
          });
        }
      }
    }
    if (Object.keys(getCount).length > 0) {
      if (getCount.isGetCount) {
        if (getCount.getCount.data) {
          const { data } = getCount.getCount;
          setTotalUser(data.data.totalUsers);
        }
      }
    }
  }, [getCountryList, checkReferLink, getSetting, getCount]);

  useEffect(() => {
    if (Object.keys(alert).length > 0) {
      setIsLoading(false);
      let tempErr = [];
      if (alert.type === "alert-success") {
        tempErr = [{ type: alert.type, message: alert.message }];
      } else if (alert.type === "alert-danger") {
        if (alert.message.errors !== undefined) {
          let keys = Object.keys(alert.message.errors);
          if (keys.length !== 0) {
            keys.map((data) => {
              tempErr.push({
                type: alert.type,
                message: alert.message.errors[data],
              });
            });
          }
        } else {
          tempErr = [{ type: alert.type, message: alert.message }];
        }
      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes("");
      }, 8000);
    }
  }, [alert]);

  useEffect(() => {
    if (Object.keys(getAdminReferal).length > 0) {
      if (getAdminReferal.getAdminReferal !== undefined) {
        const { data } = getAdminReferal.getAdminReferal;
        console.log("data.data.username", data.data.username)
        setAdminReferal(data.data.username);
      }
    }
  }, [getAdminReferal]);

  const handleChange = (e) => {
    if (e.target.name === "sponsor") {
      handleKeydown(e.target.value);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectHandler = (e) => {
    if (e !== undefined && e !== null) {
      if (e.code !== undefined && e.code !== "") {
        setContactCode(e.code);
        setFormData({ ...formData, country: e.value, countryCode: e.code });
      }
    }
  };

  const errInterval = (name) => {
    let target = name + "Err";
    setTimeout(() => {
      setErrState((state) => {
        return { ...state, [target]: "" };
      });
    }, 6000);
  };

  const handleErr = (name, msg) => {
    let target = name + "Err";
    setErrState((state) => {
      return { ...state, [target]: msg };
    });
    errInterval(name);
  };

  const handleValidation = () => {
    let formIsValid = true;
    // let passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    let numberRegex = /^[0-9]*$/;
    let spaceRegex = /^\S{1,}$/;
    let mailRegex = /^[^+]*$/;
    let usernameRegex =
      /`|~|!|@|#|\$|%|\^|&|\*|\(|\)|\+|=|\[|\{|\]|\}|\||\\|'|<|,|\.|>|\?|\/|""|;|:|-|_|\s/;
    // let usernameRegex = /`|~|!|@|#|$|%|^|&|*|(|)|+|=|[|{|]|}||||'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\-|\_|\s/;

    if (formData.name === "") {
      handleErr("name", "ERR_BLANK_NAME");
      formIsValid = false;
    }
    if (formData.email === "") {
      handleErr("email", "ERR_BLANK_EMAIL");
      formIsValid = false;
    }
    if (formData.username === "") {
      handleErr("username", "ERR_BLANK_USERNAME");
      formIsValid = false;
    }
    if (formData.country === "") {
      handleErr("country", "ERR_BLANK_COUNTRY");
      formIsValid = false;
    }
    if (formData.countryCode === "") {
      handleErr("countryCode", "ERR_BLANK_COUNTRY_CODE");
      formIsValid = false;
    }
    if (formData.password === "") {
      handleErr("password", "ERR_BLANK_USER_PASSWORD");
      formIsValid = false;
    }
    if (formData.confirmPassword === "") {
      handleErr("confirmPassword", "ERR_BLANK_CONFIRM_PASSWORD");
      formIsValid = false;
    }
    if (formData.sponsor === "") {
      handleErr("sponsor", "ERR_BLANK_SPONSOR");
      formIsValid = false;
    }
    if (formData.contact === "") {
      handleErr("contact", "ERR_BLANK_CONTACT");
      formIsValid = false;
    }
    if (formData.password !== "" && !passwordRegex.test(formData.password)) {
      handleErr("password", "ERR_8_PASSWORD_PATTERN");
      formIsValid = false;
    }
    if (
      formData.password !== "" &&
      formData.password !== formData.confirmPassword
    ) {
      handleErr("confirmPassword", "ERR_BLANK_PASSWORD_NOT_MATCH");
      formIsValid = false;
    }
    if (formData.contact !== "" && !numberRegex.test(formData.contact)) {
      handleErr("contact", "ERR_CONTACT_NOT_VALID");
      formIsValid = false;
    }
    if (formData.username !== "" && !spaceRegex.test(formData.username)) {
      handleErr("username", "ERR_SPACE_NOT_ALLOWED");
      formIsValid = false;
    }
    if (formData.username !== "" && usernameRegex.test(formData.username)) {
      handleErr("username", "ERR_SPECIAL_CHARACTER_NOT_ALLOWED");
      formIsValid = false;
    }
    // if (formData.email !== '') {
    //   var splitArray = formData.email.split('@'); // To Get Array
    //   if (rejectedDomain.indexOf(splitArray[1].toLowerCase()) >= 0) {
    //     handleErr('email', 'ERR_DOMAIN_NOT_ALLOWED')
    //     formIsValid = false;
    //   }
    // }
    if (formData.email !== "") {
      var splitEmail = formData.email.split("@");
      if (!mailRegex.test(splitEmail[0])) {
        handleErr("email", "ERR_MAIL_FORMAT");
        formIsValid = false;
      }
    }

    if (formData.terms_condition !== true) {
      handleErr("terms_condition", "ERR_TERMS_AND_CONDITION");
      formIsValid = false;
    }
    return formIsValid;
  };

  // this is for model function 
  const onSubmit = async () => {
    // if (!resendEmail.isLoading && resendEmail.isMailSend) {

    if (handleValidation()) {
      setFlag(true);
      // let obj = {
      //   email: formData.email,
      //   username: formData.username,
      //   contact: formData.countryCode + " " + formData.contact,
      //   sponsor: formData.sponsor,
      // };
      setIsLoading(true);
      // dispatch(userActions.sendEmail(apiConstants.VERIFY_REGISTER_EMAIL, obj));
    }
    // dispatch({ type: "SEND_EMAIL_RESTORE_DEFAULT" });
    let ipAddress = await publicIp.v4();
    // let key = localStorage.getItem("key");
    // var tokenValidates = speakeasy.totp.verify({
    //   secret: key,
    //   encoding: "ascii",
    //   token: formData.verificationCode.trim(),
    //   window: 1,
    //   step: 900,
    // });
    // setIsOtpLoading(true);
    // console.log(tokenValidates);
    // if (tokenValidates) {
    setShow(false);
    let name = formData.name;
    let split = name.split(" ");

    let lastname = split.pop();
    let firstname = split.join(" ");
    let obj = {
      firstname: split.length > 0 ? firstname : name,
      lastname: split.length > 0 ? lastname : "",
      countryCode: formData.countryCode,
      contact: formData.contact,
      country: formData.country,
      username: formData.username,
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      sponsor: formData.sponsor,
      role_type: "user",
      is_verified: 0,
      ipAddress: ipAddress,
      terms_condition: formData.terms_condition,
    };
    setIsLoading(false);
    dispatch(userActions.userSignup(apiConstants.PRE_REGISTER, obj));
    // } else {
    //   setShow(false);
    //   setIsOtpLoading(false);
    //   setAlertRes([{ type: "alert-error", message: "Code Expires..." }]);
    // }
    // }
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      setCheck(true);
      setFormData({ ...formData, sponsor: adminReferal });
    } else {
      setCheck(false);
      setFormData({ ...formData, sponsor: "" });
    }
  };

  return (
    <>
      <section
        className="pre-registration-page"
        style={{
          overflowX: "hidden",
          backgroundImage: `url('/assets/images/BGR.png')`
        }}
      >
        <div className="top-pre-registration-page">
          <div className="pre-page-inner-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-3 mb-4">

                </div>
                <div className="col-md-6 mb-4">
                  <div className="logo-sec">
                    <a href="/">
                      <img src="/assets/images/pre-logo.png" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="col-md-3 mb-4 d-flex align-items-center justify-content-end">
                  <span className="user-count">
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <button>{totalUser}</button>
                  </span>
                </div>
              </div>

              <div className="row  d-flex justify-content-center">
                <div className="col-lg-5 col-md-5">
                  {link && Object.keys(link).length > 0 ? (
                    Object.keys(link).map((x, i) => {
                      if (x === "registerLink" || x === "fundLink") {
                        return (
                          <div className="m-2 register-frame" key={i}>
                            <h5 className="text-light">
                              {x === "registerLink"
                                ? "How To Register ?"
                                : "How to Fund And buy position ?"}
                            </h5>
                            {x !== undefined && x !== "" ? (
                              <iframe
                                title="link"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                                                  gyroscope; picture-in-picture"
                                allowFullScreen
                                src={link[x]}
                              ></iframe>
                            ) : (
                              <img
                                src="/assets/images/poster-video.png"
                                alt="poster"
                              />
                            )}
                          </div>
                        );
                      }

                    })
                  ) : (
                    <p>No record found</p>
                  )}
                </div>
                <div className="col-lg-7 col-md-7">
                  <div className="form-sec">
                    <div>
                      {alertRes !== ""
                        ? alertRes.length > 0
                          ? alertRes.map((data, index) => {
                            return (
                              <h6
                                className={
                                  "text-danger font-error error-text" +
                                  (data.type === "alert-danger"
                                    ? "text-danger"
                                    : "text-success")
                                }
                              >
                                {" "}
                                {data.message}{" "}
                              </h6>
                            );
                          })
                          : ""
                        : ""}
                    </div>
                    <form>
                      <h1>Register Today</h1>
                      <div className="com-form">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => handleChange(e)}
                            placeholder={siteConstants.LABEL_NAME_SURNAME}
                            name="name"
                          />
                          <span className="text-danger font-error error-text">
                            {siteConstants[errState.nameErr]}
                          </span>
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => handleChange(e)}
                            placeholder={siteConstants.LABEL_USERNAME}
                            name="username"
                          />
                          <span className="text-danger font-error error-text">
                            {siteConstants[errState.usernameErr]}
                          </span>
                        </div>

                        <div className="form-group">
                          <Select
                            name="country"
                            placeholder={siteConstants.LABEL_COUNTRY}
                            onChange={(e) => selectHandler(e)}
                            options={countryList ? countryList : []}
                            styles={colourStyles}
                            className="pre-layout"
                            classNamePrefix="select-layout"
                          />
                          <span className="text-danger font-error error-text">
                            {siteConstants[errState.countryErr]}
                          </span>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-4">
                            <Select
                              name="countryCode"
                              placeholder={siteConstants.LABEL_COUNTRY_CODE}
                              value={{ value: contactCode, label: contactCode }}
                              options={codeList ? codeList : []}
                              styles={colourStyles}
                              // onChange={(e) => contactHandler(e)}
                              className="pre-layout"
                              classNamePrefix="select-layout"
                              isDisabled={true}
                            />
                            <span className="text-danger font-error error-text">
                              {siteConstants[errState.countryCodeErr]}
                            </span>
                          </div>

                          <div className="form-group col-md-8 pre-contact">
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                              placeholder={siteConstants.LABEL_CONTACT + ":"}
                              name="contact"
                            />
                            <span className="text-danger font-error error-text">
                              {siteConstants[errState.contactErr]}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            onChange={(e) => handleChange(e)}
                            placeholder={siteConstants.LABEL_EMAIL + ":"}
                            name="email"
                          />
                          <span className="text-danger font-error error-text">
                            {siteConstants[errState.emailErr]}
                          </span>
                        </div>

                        <div className="row">
                          <div className="col-lg-3 col-md-3 d-flex align-items-center">
                            <label className="sponsor-sec">
                              {siteConstants.LABEL_SPONSOR}
                            </label>
                          </div>
                          <div className="col-lg-9 col-md-9">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                onKeyDown={(e) => handleKeydown(e.target.value)}
                                readOnly={!readOnly ? check : readOnly}
                                value={formData.sponsor}
                                onChange={(e) => handleChange(e)}
                                placeholder={
                                  siteConstants.LABEL_CHOOSE_USERNAME + ":"
                                }
                                name="sponsor"
                              />
                              <span className="text-danger font-error error-text">
                                {siteConstants[errState.sponsorErr]}
                              </span>
                            </div>
                          </div>
                        </div>

                        {displayCheck ? (
                          <div className="form-group check-sponsor">
                            <input
                              type="checkbox"
                              checked={check}
                              disabled={readOnly}
                              onChange={(e) => handleCheck(e)}
                              name="sponsorCheck"
                            />
                            <label className="ml-2 f-13 mb-2 text-light">
                              {siteConstants.NO_SPONSOR}
                            </label>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="form-group position-relative">
                          <input
                            type="Password"
                            id="userpassword"
                            className="form-control"
                            onChange={(e) => handleChange(e)}
                            placeholder={
                              siteConstants.LABEL_CHOOSE_SECURE_PWD + ":"
                            }
                            name="password"
                          />
                          {!viewPassword.userpassword ? (
                            <i
                              className="fas fa-eye"
                              style={{
                                position: "absolute",
                                top: "12px",
                                right: "15px",
                              }}
                              onClick={() => Toggle("userpassword")}
                            ></i>
                          ) : (
                            <i
                              className="fas fa-eye-slash"
                              style={{
                                position: "absolute",
                                top: "12px",
                                right: "15px",
                              }}
                              onClick={() => Toggle("userpassword")}
                            ></i>
                          )}
                          <span className="text-danger font-error error-text">
                            {siteConstants[errState.passwordErr]}
                          </span>
                        </div>

                        <div className="form-group position-relative">
                          <input
                            type="Password"
                            id="confirmPassword"
                            className="form-control"
                            onChange={(e) => handleChange(e)}
                            placeholder={siteConstants.LABEL_CHOOSE_PWD + ":"}
                            name="confirmPassword"
                          />
                          {!viewPassword.confirmPassword ? (
                            <i
                              className="fas fa-eye"
                              style={{
                                position: "absolute",
                                top: "12px",
                                right: "15px",
                              }}
                              onClick={() => Toggle("confirmPassword")}
                            ></i>
                          ) : (
                            <i
                              className="fas fa-eye-slash"
                              style={{
                                position: "absolute",
                                top: "12px",
                                right: "15px",
                              }}
                              onClick={() => Toggle("confirmPassword")}
                            ></i>
                          )}
                          <span className="text-danger font-error error-text">
                            {siteConstants[errState.confirmPasswordErr]}
                          </span>
                        </div>

                        <div className="mt-2 mb-2">
                          <input
                            type="checkbox"
                            name="terms_condition"
                            checked={formData.terms_condition}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                terms_condition: e.target.checked,
                              })
                            }
                          ></input>
                          <label className="text-light ml-2">
                            <span>
                              I agree to the
                              <span
                                className="text-primary"
                                onClick={() => setTermsPopup(true)}
                              >
                                {" "}
                                Terms And Condition
                              </span>{" "}
                              and
                              <span
                                className="text-primary"
                                onClick={() => setTermsPopup(true)}
                              >
                                {" "}
                                Privacy Policy
                              </span>{" "}
                            </span>
                          </label>

                          <span className="text-danger font-error error-text">
                            {siteConstants[errState.terms_conditionErr]}
                          </span>
                        </div>

                        {!isLoading ? (
                          <button
                            type="button"
                            onClick={() => onSubmit()}
                            className="btn btn-default"
                          >
                            Complete Your Registration
                          </button>
                        ) : (
                          <button type="button" className="btn btn-default">
                            <i className="fas fa-spinner fa-spin"></i>
                          </button>
                        )}

                        <div className="signin-old">
                          <p>
                            {siteConstants.LABEL_ALREADY_HAVE_ACCOUNT}
                            <a href="/login" className="ml-2 f-18">
                              {siteConstants.LABEL_SIGNIN}
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Modal
        show={show}
        onHide={() => handleClose()}
        size="xxl"
        animation={true}
      >
        <Modal.Header closeButton>
          <div>
            <Modal.Title>{siteConstants.VERIFICATION_CODE}</Modal.Title>
            <small className="font-gray mt-2">
              We have sent an OTP to your given email address. It will expire in
              5 minutes.
            </small>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="modal-input form-group">
            <input
              type="text"
              className="form-control"
              id="verificationCode"
              onChange={(e) => handleChange(e)}
              placeholder={siteConstants.ENTER_VERIFICATION_CODE + ":"}
              name="verificationCode"
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary btn-gray" onClick={() => handleClose()}>
            {siteConstants.LABEL_CLOSE}
          </Button>
          {!isOtpLoading ? (
            <Button variant="primary" onClick={() => onSubmit()}>
              {siteConstants.BTN_SAVE}
            </Button>
          ) : (
            <button type="button" className="btn btn-default">
              <i className="fas fa-spinner fa-spin"></i>
            </button>
          )}
        </Modal.Footer>
      </Modal> */}

      {termsPopup ? <TermsAndConditionPopup onSelect={selectedTermsRes} /> : ""}
    </>
  );
};

export default Registration;

