import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions';
import { apiConstants } from '../../constants';
import Header from '../Common/Dashboard/Header';
import Sidebar from '../Common/Dashboard/Sidebar';
import Footer from '../Common/Footer/Footer';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';

const ShowVideos = () => {
    const dispatch = useDispatch();
    const [planType, setPlanType] = useState('');
    const [link, setLink] = useState({});
    const [siteConstants, setSiteConstants] = useState([]);
    console.log("link", link);

    const {
        language,
        getSetting,
    } = useSelector((state) => {
        return {
            language: state.language,
            getSetting: state.getSetting,
        };
    });

    const planHandler = (e) => {
        setPlanType(e.details);
    };

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language]);

    const initialApiCall = () => {
        setTimeout(() => {
            dispatch(userActions.getSetting(apiConstants.GET_SETTINGS));
        }, 300);
    };

    useEffect(() => {
        initialApiCall();
    }, []);

    useEffect(() => {
        if (Object.keys(getSetting).length > 0) {
            if (getSetting.isGetSetting) {
                if (getSetting.getSetting.data) {
                    const { data } = getSetting.getSetting;

                    setLink({
                        videoLink:
                            data.data.videoLink !== null && data.data.videoLink !== ""
                                ? data.data.videoLink
                                : "",
                    });
                }
            }
        }
    }, [getSetting]);

    return (
        <>
            <div className="main-wrap">
                <Header changePlan={(e) => { planHandler(e); }} />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        <div className="dashboard-content" style={{ paddingTop: "20px" }}>
                            <div className="card dash-card tag-block p-3 box_shadow">
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="row">
                                        <div className="col-lg-15" style={{ paddingLeft: "30px" , fontFamily: 'Montserrat'}}>
                                            How to place an advert:
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-15 mt-3">
                                    {link.videoLink ? (
                                        <div className="m-2 pt-2 video-frame">
                                            {link.videoLink ? (
                                                <iframe
                                                    title="video"
                                                    frameBorder="0"
                                                    width="100%"
                                                    height="450"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    src={
                                                        link.videoLink.includes("youtube.com/shorts/")
                                                            ? link.videoLink.replace("youtube.com/shorts/", "youtube.com/embed/")
                                                            : link.videoLink
                                                    }
                                                ></iframe>
                                            ) : (
                                                <img
                                                    src="/assets/images/poster-video.png"
                                                    alt="poster"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <p>No video found</p>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-15 mt-3">
                                <BannerRandomFooter />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShowVideos;
