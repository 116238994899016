export const ENGLISH = {
    SITE_NAME: 'User',

    LABEL_LOGIN_WITH_GOOGLE: 'Login with google',
    LABEL_LOGIN_WITH_FACEBOOK: ' Login with facebook',
    SIGNUP_NOW: 'Sign up now',
    LABEL_NOT_HAVE_ACCOUNT: 'Don’t have an account?',
    //-- Error Messages
    ERR_BLANK_USER_NAME: 'Enter user name',
    ERR_BLANK_USER_PASSWORD: 'Enter password',
    ERR_BLANK_EMAIL: 'Enter a email address',
    ERR_BLANK_USERNAME: 'Enter a username',
    ERR_BLANK_NEW_PASSWORD: 'Enter a new password',
    ERR_BLANK_CONFIRM_PASSWORD: 'Enter a Confirm password',
    ERR_BLANK_PASSWORD_NOT_MATCH: 'Password not matched',
    ERR_BLANK_CONTACT: 'Enter a Contact Number',
    ERR_CAN_NOT_BLANK: 'Cannot be empty',
    ERR_ONLY_LETTERS: 'Only letters',
    ERR_ONLY_NUMBERS: 'Only Number',
    ERR_MAX_LENGTH: "Max length is 3 only",
    ERR_ENTER_CREDENTIALS: "Please Enter Credentials",
    ERR_PASSWORD_PATTERN: "Minimum eight characters, at least one letter, one number and one special character",
    ERR_8_PASSWORD_PATTERN: "Minimum eight characters, at least one letter, one number",

    //-- General Messages
    GEN_ARE_YOU_SURE: 'Are you sure ?',
    GEN_NO_RECORD: 'No Record Found',
    GEN_ADD_USER: 'Add User',
    GEN_EDIT_USER: 'Edit User',
    GEN_SEARCH: 'Search',
    GEN_FIRST: 'First',
    GEN_LAST: 'Last',
    GEN_USERS: 'Users',
    GEN_DASHBOARD: 'Dashboard',
    GEN_DESCRIPTION: 'Description',
    GEN_TYPE: 'Type',
    GEN_VALUE: 'Value',
    GEN_REMARK: 'Remark',
    GEN_NUMBER: 'No',
    GEN_TITLE: 'Title',
    GEN_IMAGE: 'Image',
    GEN_NAME: 'Name',
    GEN_MESSAGE: 'Message',
    GEN_VIEW_DETAILS: 'View Details',
    GEN_PLEASE_SELECT: 'Please select',
    GEN_EXPIRY_DATE: 'Expiry Date',
    GEN_COUPON_TOKEN: 'Coupon #',
    GEN_LEGAL: 'Legal',

    //-- Headings
    LABEL_NAME: 'Name',
    LABEL_USER_NAME: 'User Name',
    LABEL_F_NAME: 'First Name',
    LABEL_L_NAME: 'Last Name',
    LABEL_CONTACT_NO: 'Contact No',
    LABEL_PASSWORD: 'Password',
    LABEL_PHONE: 'Phone',
    LABEL_STATUS: 'Status',
    LABEL_ORDER_TYPE: 'Order Type',
    LABEL_ACTION: 'Action',
    LABEL_CLOSE: 'Close',
    LABEL_REMEMBER_ME: 'Remember Me',
    LABEL_FORGET_PASSWORD: 'Forget Password',
    LABEL_FORGET_PASSWORD_MESSAGE: 'Enter your email address. You will receive a link to create a new password.',
    LABEL_BACK_TO: 'Back to',
    LABEL_LOGIN: 'Login',
    LABEL_USER_MGT: 'Users Management',
    LABEL_SETTINGS: 'Settings',
    LABEL_GENERAL_SETTINGS: 'General Settings',
    LABEL_CHANGE_PASSWORD: 'Change Password',
    LABEL_NEW_PASSWORD: 'New Password',
    LABEL_CONFIRM_PASSWORD: 'Confirm Password',
    LABEL_OLD_PASSWORD: 'Old Password',
    LABEL_PROFILE: 'Profile',
    LABEL_ADDRESS: 'Address',
    LABEL_UPLOAD_IMAGE: 'Upload Image',
    LABEL_USER_DOCUMENT: 'User Document',
    LABEL_IDENTITY_PROOF: 'Identity Image',
    LABEL_DETAILS: 'Identity Details',
    LABEL_LICENCE_NUMBER: 'License Number',
    LABEL_IDENTIFY_VERIFIED: 'Verified',
    LABEL_LICENSE: 'License',

    /* Trade */

    /* Users */
    LABEL_COMPANY_NAME: 'Company Name',
    LABEL_CERTIFICATION_NO: 'Certification Number',
    LABEL_CELL_PHONE: 'Cell Phone',
    LABEL_ROLES: 'Roles',
    LABEL_APPROVAL: 'Approval',
    LABEL_PUSH_MESSAGE: 'Push Message',
    LABEL_USER_DETAILS: 'User Details',
    LABEL_USER_CREATED_DATE: 'Created Data',
    LABEL_USER_REGISTRATION_DATE: 'Registration Date',
    LABEL_USER_APPROVAL_DATE: 'Approval Date',
    LABEL_BANK_ACCOUNTS: 'Bank Accounts',
    LABEL_BANK: 'Bank',
    LABEL_ACCOUNT: 'Account',
    LABEL_CHANGE_PASSWORD: "Change Password",

    //-- buttons
    BTN_ADD: 'Add',
    BTN_SAVE: 'Save',
    BTN_DELETE: 'Delete',
    BTN_CANCEL: 'Cancel',
    BTN_BACK: 'Back',
    BTN_SENT: 'Sent',
    BTN_COPY: 'Copy',

    //-- Menus
    MENU_LOGOUT: 'Logout',
    MENU_DASHBOARD: 'Dashboard',
    MENU_USER_PROFILE: 'User Profile',
    MENU_MEMBERSHIP_MGT: 'Membership Management',
    MENU_USER_LIST: 'Users',

    MENU_REPORTS: 'Reports',
    MENU_USER_REPORT: 'User Report',
    MENU_SETTINGS: 'Settings',
    MENU_GENERAL_SETTINGS: 'General',
    MENU_PAYMENT_TYPE: 'Payment Types',
    MENU_NOTIFICATIONS: 'Notifications',

    //-- General messages
    GEN_USERS_DESCRIPTION: 'This is user list',
    GEN_DISPLAY_DESCRIPTION: 'You can manage everything from this dashboard.',
    MSG_LOGOUT_WINDOW_READY: 'Ready to Leave?',
    MSG_LOGOUT_WINDOW_DESCRIPTION: 'Select "Logout" below if you are ready to end your current session.',
    MENU_TAX_SETTING_DESCRIPTION: 'Configure tax settings here',
    MENU_DISCOUNTS_DESCRIPTION: 'Configure discounts from here.',
    MENU_SETTINGS_DESCRIPTION: 'Configure discounts from hereConfigure discounts from here.',

    GENERAL_SETTINGS_DESC: 'View and update your store details',
    NOTIFICATIONS_DESC: 'These notifications are automatically sent out to the customer. Click on the notification template to edit the content.',


    //-- Add shopkeeper form
    LABEL_USER_SECTION_HEAD: 'PERSONAL INFO',
    LABEL_FIRST_NAME: 'First Name',
    LABEL_LAST_NAME: 'Last Name',
    LABEL_DOB: 'DOB',
    LABEL_STREET: 'Street',
    LABEL_CITY: 'City',
    LABEL_COUNTRY: 'Country',
    LABEL_ZIP: 'Zip Code',
    LABEL_MONDAY: 'Monday',
    LABEL_TUESDAY: 'Tuesday',
    LABEL_WEDNESDAY: 'Wednesday',
    LABEL_THURSDAY: 'Thursday',
    LABEL_FRIDAY: 'Friday',
    LABEL_SATURDAY: 'Saturday',
    LABEL_SUNDAY: 'Sunday',


    ERR_CONTACT_NOT_VALID: 'Contact number is not valid',
    ERR_EMAIL_NOT_VALID: 'Email address is not valid',
    CROP_IMAGE: 'Crop image',
    DONE_CROPPING: 'Done Cropping',
    CANCEL_CROPPING: 'Cancel',
    UPLOADING_IMAGE: 'Uploading image',


    GEN_FIRST_NAME: 'First Name',
    GEN_LAST_NAME: 'Last Name',
    GEN_EMAIL_ADDRESS: 'Email',
    GEN_DOB: 'DOB',
    GEN_MOBILE_NUMBER: 'Mobile',
    GEN_ACCOUNT_STATUS: 'Status',
    GEN_NO_RESULT: 'No Result',
    GEN_LOGO: 'Logo',


    LABEL_CANCEL: 'Cancel',
    LABEL_SAVE: 'Save',


    LABEL_DATE: 'Date',
    LABEL_PAYMENT: 'Payment',
    LABEL_PAYMENT_PENDING: 'Pending',
    LABEL_PAYMENT_PAID: 'Paid',
    LABEL_MARK_FULLFILLED: 'Mark as Fullfilled',
    LABEL_NOTES: 'Notes',

    // home page
    START_FUNDING: 'Start funding',
    LABEL_WHAT_WE_DO: 'What We Can Do For You',
    LABEL_MISSION_SUBHEADING: 'We Are In A Mission To Help The Helpless',
    LABEL_MARKETING: 'Marketing',
    MARKETING_SUBHEADING: 'Take Your Business Marketing To The Next',
    LABEL_OUR_CAUSES: "Our Causes",
    LABEL_CAUSES_SUBHEADING: "We’ve Done Many Crowd Funding Project",
    LABEL_READ_MORE: "Read More",
    LABEL_FEATURED_CATEGORY: "Featured Categories",
    LABEL_CATEGORY_SUBHEADING: "Our Crowd Founding Features Categories",
    LABEL_FUNDRAISNG: "FundRaising",
    LABEL_FUNDRAISNG_SUBHEADING: 'How It Works',
    LABEL_ABOUT_US: 'About US',
    LABEL_FEATURED_PROJECT: 'Features Projects',
    LABEL_FEATURED_PROJECT_SUBHEADING: 'We Offer Awesome Product For Business',
    LABEL_FUND_RAISE: 'Fund Raise',
    LABEL_FUND_RAISE_SUBHEADING: 'Raise Your Fund To The Right Place',
    LABEL_OUR_TEAM: 'Meet Our Team',
    LABEL_OUR_TEAM_HEADING: 'We’ve Most Experience Team Members',

    //mission_vision_page
    LABEL_MISSION_VISION: 'Mission Vision',
    LABEL_OUR_MISSION: 'Our Mission',
    LABEL_OUR_MISSION_SUBHEADING: 'We Are Trusted Crowdfunding',
    LABEL_RAISE_FUND: 'We Are In A Mission To Raise More Fund',
    LABEL_OUR_VISION: 'Our Vision',
    LABEL_SUCCESS_RATE: 'Highest Success Rates',
    LABEL_FAQ: 'FAQ',
    LABEL_QUERIES: 'Have Any queries?',
    LABEL_FREQUENT_ASKED_QUESTION: 'Frequently Asked Questions',

    // newsletter page

    LABEL_NEWSLETTER: 'Sign up for our Newsletter',
    LABEL_NEWSLETTER_SUBHEADING: 'Be the first to know about new Sessions and Educational Events',
    LABEL_JOIN_NOW: 'Join now',

    //contact page
    LABEL_CONTACT_US: 'Contact Us',
    LABEL_CONTACT_SUBHEADING: 'Get in Touch',
    LABEL_SUBMIT: 'Submit',
    LABEL_CONTACT_DETAILS: 'Contact details',

    //FORGOT PAGE

    LABEL_RESET: 'Reset',

    // register page

    LABEL_CREATE_ACCOUNT: 'Create Account',
    LABEL_SIGNUP: 'Sign up',
    LABEL_SIGNIN: 'Sign in',
    LABEL_ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    LABEL_NAME_SURNAME: 'Name & Surname',
    LABEL_COUNTRY_CODE: 'Code',
    LABEL_CONTACT: 'Contact Number',
    LABEL_EMAIL: 'Email Address',
    LABEL_CHOOSE_USERNAME: 'Choose a Username',
    LABEL_CHOOSE_SECURE_PWD: 'Choose a Secure Password',
    LABEL_CHOOSE_PWD: 'Confirm Chosen Password',
    LABEL_SPONSOR: "Sponsor",
    LABEL_USERNAME: "Username",
    CLAIM_POSITION: "Claim Your Position",
    VERIFICATION_CODE: "Verification Code",
    ENTER_VERIFICATION_CODE: "Enter Verfication Code",
    ERR_BLANK_COUNTRY_CODE: 'Enter a Country Code',
    ERR_BLANK_SPONSOR: 'Enter a Sponsor ID',
    NO_SPONSOR: "I don't have any sponsor",
    VERIFY_EMAIL: "Verify Email",
    USER_DETAILS: "User Details",
    MENU_MY_PROFILE: "My Profile",
    MENU_ACCOUNT_SETTING: "Account Setting",
    MENU_ORDER_HISTORY: "Order History",
    REFERAL_LINK_HEADING: "Your Diamond Jubilee 7 refferal link",
    POTENTIAL_MEMBERS: "Potential Members You have Introduced",
    ERR_DOMAIN_NOT_ALLOWED: 'Domain not Allowed',
    LOGIN_CODE: "Login Code",
    ERR_SPACE_NOT_ALLOWED: "Space Not allowed",
    ERR_BLANK_COUNTRY: "Enter country name",
    PROFILE_UPDATE_CODE: "Profile Update Code",
    PERSONALISED_MESSAGE: "Personalised Message",
    ERR_MAIL_FORMAT: "Email with '+' is not allowed",
    ADD_WALLET: 'Create Wallet',
    SEND_MONEY: 'Send Money',
    LABEL_WALLET: 'Wallet',
    LABEL_USER_LIST: 'User List',
    LABEL_NOMINEE: 'Nominee User',
    BTN_CREATE: 'Create',
    LABEL_YES: 'Yes',
    LABEL_NO: 'No',
    UPDATE_BALANCE: 'Update Balance',
    CONFIRM_TRANSFER: 'Confirm Transaction',
    BTN_SEND: 'Send',
    BTN_CONFIRM: 'Confirm',
    LABEL_POSITION_LIST: 'Position List',
    LABEL_REENTRY_LIST: 'Re-Entry List',
    WITHDRAW_MONEY: 'Withdraw Money',
    ARE_YOU_SURE_YOU_WANT_TO_WITHDRAW: 'Are you sure you want to withdraw',
    RE_ENTRY_LIST: "Re-Entry Listing",
    POSITION_LIST: "Position Listing",
    SELECT_PREFERED_LANGUAGE_FILE: 'Select Prefered Language',
    ERR_SPECIAL_CHARACTER_NOT_ALLOWED: 'Special Character and space not allowed',
    ERR_BLANK_NAME: 'Enter name',
    SEARCH_BY_POSITION_NUMBER: 'Position Number',
    GEN_SHARE_OPTION: 'Share Option',
    LABEL_ADD_TESTIMONIALS: 'Add Testimonials',
    LABEL_MESSAGE: "Message",
    LABEL_COMPETITION: "Competition",
    LABEL_WINNERS: 'Winners',
    GEN_TERMS_AND_CONDITION: 'Terms And Condition',
    GEN_PRIVACY_POLICY: 'Privacy Policy',
    GEN_COOKIES_NOTICE: 'Cookies Notice',
    GEN_COOKIES_POLICY: 'Cookies Policy',
    ERR_TERMS_AND_CONDITION: 'You need to agree with the terms and conditions',
    LABEL_FILE: 'File',
    LABEL_GENERATE_NEW_TICKET: "Generate new ticket",
    LABEL_SUBJECT: "Subject",
    LABEL_DESCRIPTION: "DESCRIPTION",
    LABEL_PRIORITY: "Priority",
    LABEL_CATEGORY: "Category",
    LABEL_SUPPORT: "Support",
    GEN_EDIT_TICKET: "Edit Ticket",
    GEN_COMMENT_ON_TICKET: "Comment on Ticket",
    GEN_VIEW_TICKET: "View Ticket Details",
    LABEL_ATTACHMENT: "Attachment",
    OTP_CODE: "Otp Code",
    GEN_UPDATE_WALLET: "Update Wallet",
    GEN_CREATE_WALLET: "Add Wallet",
    BTN_UPDATE: "Update Balance",
    LABEL_MNEMONIC: "Menomic",
    LABEL_RECOVER_WALLET: "Recover And Update Wallet",
    LABEL_GLOBAL_POOL: "Global Pool",
    ADVERTISMENT_ADD: "Add Advertisment",
    SHOWVIDEO: "How to place an advert",
    ADVERTISMENT_LISTING: "Advertisment Listing",
    CREDIT_LISTING: "Advertisment Transaction",
    BANNER_TITILE: "Banner Titile",
    BANNER_DESCRIPTION: "Banner description",
    TYPE: "Type",
    BANNER_IMAGE: "Banner image",
    TEXT: "Advertisment Text",
    ACTIVE: "Active",
    ADVERTISMENT: "Advertisment type",
    CREDIT: "Credit",
    LINK: "Link",
    NextInLine: "Next In Line",
    Mat1: "Matrix round 1",
    Mat2: "Matrix round 2",
    Mat3: "Matrix round 3",
    Mat4: "Matrix round 4",
    Mat5: "Matrix round 5",
    Mat6: "Matrix round 6",
    Mat7: "Matrix round 7",
    Mat8: "Matrix round 8",
    Number: "Number:",
    UserName: "UserName:",
    MatrixID: "Matrix ID:",
    Name: "Name:",
    EmailId: "Email ID:"


};
