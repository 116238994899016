import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, userActions, genealogyActions, walletAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import DiamondStructure from '../DiamondStructure/DiamondStructure';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Select from 'react-select';
import { history } from '../../helpers';
import { useLocation } from 'react-router-dom';
import TreeStructure from '../Matrix/TreeStructure';
const Dashboard = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const [alertRes, setAlertRes] = useState('');
  const [diamondPosition, setDiamondPosition] = useState('');
  const [downlineMatrix, setDownlineMatrix] = useState('');
  const [siteConstants, setSiteConstants] = useState([]);
  const [positionOwned, setPositionOwned] = useState()
  const [memberList, setMemberList] = useState([]);
  const [positionNumber, setPositionNumber] = useState({ 'label': 1, value: 1 });
  const [isCopied, setIsCopied] = useState(false);
  const [walletDetails, setWalletDetails] = useState('');
  const [positionPrice, setPositionPrice] = useState('');
  const [reEntriesPosition, setReEntriesPosition] = useState([])
  const [isSpin, setIsSpin] = useState(false);
  const [walletPassword, setWalletPassword] = useState('');
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [sponsorName, setSponsorName] = useState('');
  const [positionSearch, setPositionSearch] = useState('');
  const [userData, setUserData] = useState('');
  const [planType, setPlanType] = useState('');
  const [currentPlanName, setCurrentPlanName] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [arrMatrix, setArrMatrix] = useState([]);
  const [previousData, setPreviousData] = useState([])

  const dropDownCount = 1001;
  let sessionPlan = sessionStorage.getItem('planType');

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const { alert, language, getPurchasedPosition, getReEntryPosition, getWallet, getNewMember, getBalance,
    addedPosition, getPositionView, positionByNumber, getEarnedIncome, generateReferalLink, getPendingPositionCount } = useSelector(
      state => ({
        alert: state.alert,
        language: state.language,
        getPurchasedPosition: state.getPurchasedPosition,
        getReEntryPosition: state.getReEntryPosition,
        getNewMember: state.getNewMember,
        getBalance: state.getBalance,
        addedPosition: state.addPosition,
        getWallet: state.getWallet,
        getPositionView: state.getPositionView,
        positionByNumber: state.positionByNumber,
        getEarnedIncome: state.getEarnedIncome,
        generateReferalLink: state.generateReferalLink,
        getPendingPositionCount: state.getPendingPositionCount
      })
    );

  // console.log("getPendingPositionCount", JSON.stringify(getPendingPositionCount?.getPendingPositionCount?.data?.data));
  // console.log("getPendingPositionCount", getPendingPositionCount?.getPendingPositionCount?.data?.data?.remainingBuys);
  const pendingCount = getPendingPositionCount?.getPendingPositionCount?.data?.data?.remainingBuys;

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  // const viewData = () => {
  //   console.log(position , "---->")
  // }


  const handlePurchase = () => {
    if (positionNumber !== '') {
      if (!isSpin) {
        setIsSpin(true);
        let sessionPlan = sessionStorage.getItem('planType');
        let apiName = apiConstants.PLAN_ADD_POSITION;

        if (sessionPlan && JSON.parse(sessionPlan).status === 1) {
          apiName = apiConstants.PLAN_ADD_POSITION
        } else if (sessionPlan && JSON.parse(sessionPlan).status === 2) {
          apiName = apiConstants.MATRIX_ADD_POSITION
        }
        else if (sessionPlan && JSON.parse(sessionPlan).status === 3) {
          apiName = apiConstants.SUPER_MATRIX_ADD_POSITION
          pendingPosition()
        }
        // console.log("fifhdsjkfskdjf", positionNumber.value, JSON.parse(sessionPlan))
        dispatch(genealogyActions.addPosition(apiName,
          { 'password': walletPassword, positionCount: positionNumber.value, plan: JSON.parse(sessionPlan) }));
        setWalletPassword('')
      }
    }
    else {
      console.log("position number not selected ....")
    }
  }

  const initialApiCalls = () => {
    let sessionPlan = sessionStorage.getItem('planType');
    // viewData()
    let obj = { page: 1, perPage: 4, orderByAscDesc: -1, limitQuery: true };
    let obj1 = { page: 1, perPage: 5, orderByAscDesc: -1, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } };
    let reEntryApiName = apiConstants.GET_RE_ENTRY_POSITION;
    let purchaseApiName = apiConstants.GET_PURCHASED_POSITION;
    let getViewApiName = apiConstants.GET_POSITION_VIEW;

    if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
      reEntryApiName = apiConstants.RE_ENTRY_PLAN_POSITION;
      purchaseApiName = apiConstants.PURCHASE_POSITION_PLAN_POSITION;
      getViewApiName = apiConstants.GET_POSITION_PLAN_VIEW;
    }
    else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2)) {
      reEntryApiName = apiConstants.MATRIX_RE_ENTRY_PLAN_POSITION;
      purchaseApiName = apiConstants.MATRIX_POSITION_PLAN_POSITION;
      getViewApiName = apiConstants.MATRIX_GET_PLAN_POSITION_PLAN_VIEW;
    }
    else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3)) {
      reEntryApiName = apiConstants.SUPER_MATRIX_RE_ENTRY_PLAN_POSITION;
      purchaseApiName = apiConstants.SUPER_MATRIX_POSITION_PLAN_POSITION;
      getViewApiName = apiConstants.SUPER_MATRIX_GET_PLAN_POSITION_PLAN_VIEW;
    }
    if (sessionPlan && Object.keys(sessionPlan).length > 0) {
      if (JSON.parse(sessionPlan).defaultName) {
        let temp = JSON.parse(sessionPlan).defaultName.split('-');
        setCurrentPlanName(temp[0].toLowerCase())
      }
    }
    dispatch(alertActions.clear());
    dispatch({ type: 'POSITION_RESTORE_DEFAULT' });
    dispatch(userActions.generateReferalLink(apiConstants.GET_LINK));
    dispatch(walletAction.getWallet(apiConstants.GET_WALLET));
    dispatch(genealogyActions.getPurchasedPosition(purchaseApiName, obj1));
    dispatch(genealogyActions.getReEntryPosition(reEntryApiName, obj1))
    dispatch(genealogyActions.getNewMember(apiConstants.GET_NEW_MEMBERS, obj));
    dispatch(genealogyActions.getPositionView(getViewApiName, { plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } }));
    dispatch(genealogyActions.getEarnedIncome(apiConstants.GET_EARNED_INCOME));

  }




  useEffect(() => {
    initialApiCalls()
  }, [planType])

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language])

  useEffect(() => {
    let tempArr = [];
    const userDetails = JSON.parse(sessionStorage.getItem('user'));
    let sessionPlan = sessionStorage.getItem('planType');
    let tempDropDownCount = dropDownCount;

    if (userDetails && userDetails.role_type && Array.isArray(userDetails.role_type) && userDetails.role_type.includes('company')) {
      tempDropDownCount = 1001;
    }

    for (let i = 1; i < tempDropDownCount; i++) {
      tempArr.push({ 'label': i, 'value': i });
    };
    if (sessionPlan && Object.keys(sessionPlan).length > 0) {
      setPlanType(JSON.parse(sessionPlan))
    }
    setUserData(userDetails)
    setDownlineMatrix('');
    setDiamondPosition('');
    setSelectedPosition(tempArr);
    // initialApiCalls()

    return () => {
      setAlertRes('');
      setDownlineMatrix('');
      setDiamondPosition('');
      dispatch({ type: 'POSITION_RESTORE_DEFAULT' });
    }
  }, []);


  const previousHandler = (e) => {
    let temp = previousData;
    temp.push(e)
    setPreviousData(temp);
  }

  useEffect(() => {
    if (Object.keys(alert).length > 0) {

      let tempErr = [];
      if (alert.type === 'alert-success' && alert.message !== undefined) {
        tempErr = [{ 'type': alert.type, 'message': alert.message }]
      }
      else if (alert.type === 'alert-danger' && alert.message !== undefined) {
        if (alert.message.errors !== undefined && alert.message.errors !== null) {

          if (typeof alert.message.errors == 'object') {

            let keys = Object.keys(alert.message.errors)

            if (keys.length !== 0) {
              keys.map(data => {
                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
              })
            }
          }
          else {

            tempErr = [{ 'type': alert.type, message: alert.message.errors }]
          }
        }
        else {
          tempErr = [{ 'type': alert.type, message: alert.message }]
        }

      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 9000);
    }


    if (getPurchasedPosition !== undefined && getPurchasedPosition !== null) {
      if (Object.keys(getPurchasedPosition).length > 0) {
        if (getPurchasedPosition.isGetPurchasedPosition) {
          setPositionOwned(getPurchasedPosition.getPurchasedPosition.data.data)
        }
      }
    }

    if (getReEntryPosition !== undefined && getReEntryPosition !== null) {
      if (Object.keys(getReEntryPosition).length > 0) {
        if (getReEntryPosition.isGetReEntryPosition) {
          setReEntriesPosition(getReEntryPosition.getReEntryPosition.data.data)
        }
      }
    }

    if (getNewMember !== undefined && getNewMember !== null) {
      if (Object.keys(getNewMember).length > 0) {
        if (getNewMember.isGetNewMember) {
          setMemberList(getNewMember.getNewMember.data.data.items)
          setPositionPrice(getNewMember.getNewMember.data.data.positionPrice)
        }
      }
    }

    if (Object.keys(getEarnedIncome).length > 0) {
      if (getEarnedIncome.isgetEarnedIncome) {
        if (getEarnedIncome.getEarnedIncome) {
          if (getEarnedIncome.getEarnedIncome.data !== undefined) {
            setWalletDetails({
              ...walletDetails, 'spentIncome': getEarnedIncome.getEarnedIncome.data.data,
              'earnedIncome': getEarnedIncome.getEarnedIncome.data.earned,
              'lostIncome': getEarnedIncome.getEarnedIncome.data.lostIncome
            })
          }
        }
      }
    }

    if (getPositionView !== undefined && getPositionView !== null) {
      if (Object.keys(getPositionView).length > 0) {
        if (getPositionView.getPositionView) {
          setDiamondPosition(getPositionView.getPositionView.data)
        }
      }
    }

  }, [alert, getPurchasedPosition, getReEntryPosition, getNewMember, getBalance,
    getPositionView, getEarnedIncome]);

  useEffect(() => {
    if (Object.keys(getWallet).length > 0) {

      if (getWallet.isGetWallet) {
        if (getWallet.getWallet) {
          if (getWallet.getWallet.data !== undefined) {
            let obj = {
              ...walletDetails,
              walletAddress: getWallet.getWallet.data.data.walletAddress,
              walletBalance: getWallet.getWallet.data.data.balance, credit: getWallet.getWallet.data.data.credit,
              withdrawBalance: getWallet.getWallet.data.data.withdrawBalance,
              repurchaseBalance: getWallet.getWallet.data.data.repurchaseBalance,
              reWithdrawBalance: getWallet.getWallet.data.data.reWithdrawBalance,
              loginCredit: getWallet.getWallet.data.data.loginCredit,
              textCredit: getWallet.getWallet.data.data.textCredit,
              bannerCredit: getWallet.getWallet.data.data.bannerCredit
            };
            setWalletDetails(obj)
          }
        }
      }
    }
  }, [getWallet])

  useEffect(() => {
    if (Object.keys(generateReferalLink).length > 0) {
      if (generateReferalLink.isGenerateLink) {
        let code = generateReferalLink.generateLink.data.data

        if (code.sponsorName !== undefined && code.sponsorName !== null) {
          if (code.sponsorName.directSponsor !== undefined && code.sponsorName.directSponsor !== null) {
            setSponsorName(code.sponsorName.directSponsor.username)
          }
        }
      }
    }
  }, [generateReferalLink])

 

  useEffect(() => {

    // if (addedPosition !== undefined && addedPosition !== null) {
    if (Object.keys(addedPosition).length > 0) {

      if (addedPosition.isPositionAdded && addedPosition.positionAdded) {
        dispatch({ type: 'ADD_POSITION_RESTORE_DEFAULT' });
        dispatch(walletAction.getWallet(apiConstants.GET_WALLET));
        setIsSpin(false)
        // renderDiamond()
      }
      else {
        if (addedPosition.positionAdded !== undefined) {
          if (!addedPosition.isPositionAdded && !addedPosition.positionAdded) {
            setWalletPassword('');
            setIsSpin(false)
          }
        }
      }
    }
    // }
  }, [addedPosition])

  useEffect(() => {
    if (positionByNumber !== undefined && positionByNumber !== null) {
      if (Object.keys(positionByNumber).length > 0) {
        if (positionByNumber.positionByNumber) {
          setDownlineMatrix(positionByNumber.positionByNumber.data.data[0])
        }
      }
    }
  }, [positionByNumber])

  const view = (positionNo, actualPositionNumber, name) => {
    // let sessionPlan = sessionStorage.getItem('planType');
    let positionNoApiName = apiConstants.POSITION_BY_NUMBER;
    let searchApiName = apiConstants.SEARCH_POSITION_NUMBER;

    if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
      positionNoApiName = apiConstants.PLAN_POSITION_BY_NUMBER;
      searchApiName = apiConstants.PLAN_SEARCH_POSITION_NUMBER
    }
    if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2)) {
      history.push('/matrix-genealogy', { state: actualPositionNumber ? actualPositionNumber : positionNo });
      return true;
    } else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3)) {
      history.push('/matrix-genealogy', { state: actualPositionNumber ? actualPositionNumber : positionNo });
      return true;
    }

    if (location.pathname === '/genealogy') {
      dispatch(genealogyActions.positionByNumber(positionNoApiName, { positionNumber: positionNo, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } }));
    }
    else if (name === "search") {
      dispatch(genealogyActions.positionByNumber(searchApiName, { positionNumber: positionNo, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } }));
    }
    else {
      // history.push('/genealogy', { state: positionNo, input: 'view' });
      history.push('/genealogy', { state: positionNo });
    }
  }

  const planHandler = (e) => {
    setPlanType(e.details)
  }

  const positionAlertFunc = (data, index) => {

    if (new RegExp(/specialPositionAlert/).test(data.message)) {
      let temp = data.message.split('^')
      return (
        <p className={data.type === 'alert-danger' ? 'p-1 text-danger bg-light-red' : 'p-1 text-success bg-light-green'} key={index}><b>{temp[0]}</b></p>
      )
    }

  }

  const alertFunc = (data, index) => {
    if (!new RegExp(/specialPositionAlert/).test(data.message)) {
      return (
        <Alert style={{
          position: "absolute",
          top: "30px", zIndex: "+1",
          width: "90%"
        }} key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
          className="alertBox">
          {data.message}
        </Alert>)
    }
  }

  useEffect(() => {
    if (getPositionView && Object.keys(getPositionView).length > 0) {
      if (getPositionView.getPositionView) {
        let dumm_arr = [];
        let tempArr = [];
        if (getPositionView?.getPositionView?.data?.data?.length > 0) {
          getPositionView?.getPositionView?.data?.data?.map((item) => {
            return dumm_arr.push(item)
          })

        }
        if (dumm_arr && dumm_arr?.length > 0) {
          for (let i = 0; i < 5; i++) {
            tempArr.push(i)
          }
        }
        setSelectedLevel(tempArr)
        setArrMatrix(dumm_arr)
      }
    }


  }, [getPositionView]);


  useEffect(() => {
    pendingPosition()
  }, [])

  const userName = JSON.parse(sessionStorage.getItem('user'));
  // console.log("user", userName.email)

  const pendingPosition = () => {
    dispatch(genealogyActions.getPendingPositionCount(apiConstants.PENDING_POSITION_COUNT, { email: userName.email }));
  }

  return (
    <>
      <div className="main-wrap">
        <Header changePlan={(e) => { planHandler(e) }} />
        <div className="dashboard-wraper">
          <Sidebar />
          <div className="content-wrap">
            {alertRes !== '' ?
              alertRes.length > 0 ? alertRes.map((data, index) => {
                return alertFunc(data, index)
              }) : '' : ''}

            <div className="dashboard-content p">
              <div className="dash-body-blk">
                <div className="dash-report-blk px-xl-4 px-2 pt-3">
                  <div className={"card dash-card tag-block p-3 box_shadow" +
                    (currentPlanName === "dj7lite" ? 'dj-lite-bg' : currentPlanName === "dj7premier" ? 'dj-premier-bg' : '')}>
                    <h2 className="heading-top">Overview</h2>
                    <div className="row progress-bar-sec">
                      <div className="col-xl-6 col-lg-6 col-md-12 mb-4 inner-progress-bar-sec">
                        <div className="card dash-card tag-block p-3 box_shadow w-100 ">

                          <div className="card-ttl overflow-x-auto d-flex flex-wrap justify-content-between row scroll-layout" style={{ height: '50vh' }}>
                            <div className="d-flex w-100">
                              <div className="img-box-left col-lg-12 col-md-12 p-2" style={{ width: '100%' }}>
                                <h2 className="heading-top f-18">Your Sponsor
                                  <span className='text-success ml-2'><b>{sponsorName !== undefined && sponsorName !== null ? sponsorName : ''}</b></span>
                                </h2>

                                {/* {
                                  sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2 ?
                                    <div>
                                      {(sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2) ?
                                        <div>
                                          <TreeStructure isDashboard={true} translateX="190" translateY="115" width="80%" height="100vh" levelNumber={selectedLevel} matrixPosition={arrMatrix} />
                                          : <DiamondStructure key={"one"} matrixPosition={diamondPosition !== '' ? diamondPosition : ''} />
                                        </div>
                                        : ''}
                                    </div> :
                                    <div>
                                      {(sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3) ?
                                        <div>
                                          <TreeStructure isDashboard={true} translateX="190" translateY="115" width="80%" height="100vh" levelNumber={selectedLevel} matrixPosition={arrMatrix} />
                                          : <DiamondStructure key={"one"} matrixPosition={diamondPosition !== '' ? diamondPosition : ''} />
                                        </div>
                                        : ''}
                                    </div>
                                } */}
                                {
                                  (sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3) ?
                                    // <TreeStructure isDashboard={true} translateX="190" translateY="115" width="80%" height="100vh" levelNumber={selectedLevel} matrixPosition={arrMatrix} />
                                    <TreeStructure isDashboard={true} translateX="250" translateY="115" width="100%" height="100vh" levelNumber={selectedLevel} matrixPosition={arrMatrix} />
                                    : <DiamondStructure key={"one"} matrixPosition={diamondPosition !== '' ? diamondPosition : ''} />
                                }
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 mb-4 inner-progress-bar-sec">
                        <div className="card dash-card tag-block p-3 box_shadow w-100">

                          <div className="card-ttl overflow-x-auto d-flex flex-wrap justify-content-between row scroll-layout" style={{ height: '50vh' }}>
                            <div className="d-flex w-100">

                              <div className="img-box-left col-lg-12 col-md-12 p-2 text-center" style={{ width: '100%' }}>
                                <h2 className="heading-top f-18">Your Downline</h2>

                                <div className='d-flex mb-2 downline-search'>
                                  <input type="text" placeholder={siteConstants.SEARCH_BY_POSITION_NUMBER} className='form-control ml-3'
                                    style={{ 'width': '80%' }} name='search' value={positionSearch}
                                    onChange={(e) => { setPositionSearch(e.target.value) }}></input>
                                  <button type='button' style={{
                                    'border': '1px solid',
                                    'padding': '3px',
                                    'width': '30%',
                                    'backgroundColor': 'transparent',
                                    'color': '#007bff'
                                  }}
                                    className="btn btn-primary" onClick={() => view(positionSearch, '', 'search')}>
                                    Search
                                  </button>
                                </div>
                                {
                                  (sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3) ?
                                    <TreeStructure isDashboard={true} translateX="250" translateY="110" width="100%" height="100vh" levelNumber={selectedLevel} matrixPosition={arrMatrix} />
                                    : <DiamondStructure key={"two"} matrixPosition={downlineMatrix !== '' ? downlineMatrix : ''} />
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 d-flex col-md-6 mb-4 inner-progress-bar-sec progress-bar-sec top-right-sec">
                        <div className="card dash-card tag-block p-3 box_shadow w-100">
                          <h2 className="heading-top">Positions Owned</h2>
                          <div className="owned-sec scroll-layout">
                            <table>
                              <thead>
                                <tr>
                                  <th>Board Number</th>
                                  <th>Position Number</th>
                                  <th>Purchase Date</th>
                                  <th>Round</th>
                                  {/* <th>Status</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {positionOwned !== undefined && positionOwned !== null ?
                                  positionOwned.items.length > 0 ? positionOwned.items.map((data, index) => {
                                    let d = new Date(data.createdAt)
                                    return (
                                      <tr key={index}>
                                        <td>{data.boardNumber !== null && data.boardNumber !== undefined ?
                                          data.boardNumber.positionNumber : '-'}</td>
                                        <td><p className="text-primary notify-cursor" onClick={() => view(data?.boardNumber?.positionNumber, data?.positionNumber)}>{data.positionNumber}</p></td>
                                        <td>{d.toLocaleString("en-US", options)}</td>
                                        <td className="color-b">{data.userCurrentRound}</td>
                                        {/* <td>Closed</td> */}
                                      </tr>
                                    )
                                  })

                                    : <tr><td colSpan="8">No Record Found</td></tr>
                                  : <tr><td>'-'</td></tr>}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="4" align="right"><a style={{ 'textDecoration': 'underline' }} href="/position-listing">view all</a></td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div >
                      </div >
                      <div className="col-xl-6 col-lg-6 d-flex col-md-6 mb-4 inner-progress-bar-sec progress-bar-sec top-right-sec">
                        <div className="card dash-card tag-block p-3 box_shadow w-100">
                          <h2 className="heading-top mt-4">Re Entries - Owned</h2>
                          <div className="owned-sec scroll-layout">
                            <table>
                              <thead>
                                <tr>
                                  <th>Board Number</th>
                                  <th>Parent</th>
                                  <th>Starting Date</th>
                                  <th>Position</th>
                                  <th>Round</th>
                                  {/* <th>Status</th> */}
                                  {/* <th>Total</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {reEntriesPosition !== undefined && reEntriesPosition !== null ? reEntriesPosition.items !== undefined &&
                                  reEntriesPosition.items.length > 0 ? reEntriesPosition.items.map((data, index) => {
                                    let d = new Date(data.createdAt)

                                    return (
                                      <tr key={index}>
                                        <td>{data.boardNumber !== null && data.boardNumber !== undefined ?
                                          data.boardNumber.positionNumber : '-'}</td>
                                        <td>{data.re_entry_parent.positionNumber}</td>
                                        <td>{d.toLocaleString("en-US", options)}</td>
                                        <td><p className="text-primary notify-cursor" onClick={() => view(data.boardNumber.positionNumber, data?.positionNumber)}>
                                          {data.positionNumber}</p></td>
                                        <td>{data.userCurrentRound}</td>
                                        {/* <td></td> */}
                                        {/* <td></td> */}
                                      </tr>
                                    )
                                  })

                                  : <tr><td colSpan="8" className="ml-2">No Record Found</td></tr>
                                  : <tr><td>'-'</td></tr>}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="5" align="right"><a style={{ 'textDecoration': 'underline' }} href="/re-entry-listing">view all</a></td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row progress-bar-sec">
                          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                            <div className="card dash-card tag-block p-3 box_shadow">
                              <h2 className="heading-top">Members You Have Introduced</h2>
                              <div className="owned-sec scroll-layout">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Joined date</th>
                                      <th>Status</th>
                                      <th>Position Count</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {memberList !== undefined && memberList !== null ?
                                      memberList.length !== 0 ?
                                        memberList.map((data, index) => {
                                          let user = data.items[0].userDetail;

                                          let firstname = user.firstname !== undefined && user.firstname !== null ? user.firstname : ''
                                          let lastname = user.lastname !== undefined && user.lastname !== null ? user.lastname : ''
                                          let name = firstname + " " + lastname;
                                          let d = new Date(user.created_date);


                                          let count = data.items[0].matrixDetails ? 1 : 0 + data.items[0].planDetails ? 1 : 0 +
                                            data.items[0].positionDetails ? 1 : 0

                                          let status = 'pending';
                                          if (count) {
                                            if (count > 0) {
                                              status = 'approved'
                                            }
                                          }

                                          let amountSpent = ''
                                          if (positionPrice !== undefined && positionPrice !== null && positionPrice !== '') {
                                            amountSpent = parseInt(data.items[0].positionCount)
                                          }
                                          return (
                                            <tr key={index}>
                                              <td>{name}</td>
                                              <td>{d.toLocaleString("en-US", options)}</td>
                                              <td className={status === 'approved' ? 'text-success' : 'text-danger'}>{status}</td>
                                              <td>{amountSpent}</td>
                                            </tr>
                                          )
                                        })
                                        : <tr><td colSpan="8"> No Members Added Yet </td></tr>
                                      : <tr><td>'-'</td></tr>}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="4" align="right"><a style={{ 'textDecoration': 'underline' }} href="/user-listing">view all</a></td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row progress-bar-sec">
                          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 inner-progress-bar-sec">
                            <div className="card dash-card tag-block p-3 box_shadow">
                              {/* Wallet Balance */}

                              <h2 className="heading-top">Wallet Balance</h2>
                              <div className='d-flex my-2'>
                                {walletDetails.repurchaseBalance ? <p className='font-weight-bold mr-2 d-block f-14'>Re-Purchase Balance  <span className='font-weight-normal'>{" " + parseFloat(walletDetails.repurchaseBalance).toFixed(2)}</span> </p> : ''}
                                {walletDetails.reWithdrawBalance ? <p className='font-weight-bold mr-2 d-block f-14'>Withdraw Balance   <span className='font-weight-normal'>{" " + parseFloat(walletDetails.reWithdrawBalance).toFixed(2)}</span> </p> : ''}
                              </div>
                              {/* Wallet Address and copy button */}
                              <div className="warning-sec d-flex flex-wrap align-items-center shadow-sm mb-2 bg-white rounded">
                                {/* Credit Balance */}
                                <div className='d-flex justify-content-between my-2'>
                                  {walletDetails.loginCredit ? <p className='font-weight-bold mr-2 d-block f-14'>Login Credit  <span className='font-weight-normal'>{" " + parseFloat(walletDetails.loginCredit).toFixed(2)}</span> </p> : ''}
                                  {walletDetails.textCredit ? <p className='font-weight-bold mr-2 d-block f-14'> Text Credit   <span className='font-weight-normal'>{" " + parseFloat(walletDetails.textCredit).toFixed(2)}</span> </p> : ''}
                                  {walletDetails.bannerCredit ? <p className='font-weight-bold mr-2 d-block f-14'> Banner Credit  <span className='font-weight-normal'>{" " + parseFloat(walletDetails.bannerCredit).toFixed(2)}</span> </p> : ''}

                                </div>

                                {walletDetails.withdrawBalance && walletDetails.withdrawBalance !== '0' ?
                                  <h6 className='text-secondary w-100'>Withdrawal Requested for
                                    <span className='text-danger'>{" " + walletDetails.withdrawBalance}</span> </h6>
                                  : ''}

                                <h6 className='text-secondary font-weight-bold my-3 w-100' style={{ "textTransform": "none", 'overflow': 'auto' }}>

                                  <p className='my-2 font-weight-bold'>{walletDetails.walletAddress && walletDetails.walletAddress !== '' ? walletDetails.walletAddress : 'Wallet not created yet'}</p>
                                  {/* <a href="#" className="btn btn-new blue-layout">Copy Address</a> */}
                                  {walletDetails.walletAddress && walletDetails.walletAddress !== '' ?
                                    <CopyToClipboard text={walletDetails.walletAddress} onCopy={onCopyText}>
                                      <div className="copy-area">
                                        <button className="btn btn-new" style={{ 'marginLeft': '0px' }}>Copy Address</button>
                                        {isCopied ?
                                          <span className={isCopied ? "text-primary" : ""}>
                                            Copied!
                                          </span> : ''}
                                      </div>
                                    </CopyToClipboard>
                                    : ''}
                                </h6>
                              </div>
                              <div className="m-2 shadow-sm p-2">
                                <button type="button" className="btn btn-outline-danger m-2">
                                  <p className="m-1"><b>{pendingCount !== undefined ? pendingCount : 0}</b></p>
                                  <small>Pending Position</small>
                                </button>
                                <button type="button" className="btn btn-outline-danger m-2">
                                  <p className="m-1"><b>{walletDetails.spentIncome !== undefined ? walletDetails.spentIncome.toFixed(2) : 0}</b></p>
                                  <small>Donation sent</small>
                                </button>
                                <button type="button" className="btn btn-outline-success m-2">
                                  <p className="m-1"><b>{walletDetails.earnedIncome !== undefined ? walletDetails.earnedIncome.toFixed(2) : 0}</b></p>
                                  <small>Donation Received</small>
                                </button>
                                {walletDetails.lostIncome && Object.keys(walletDetails.lostIncome).length > 0 ?
                                  <button type="button" className="btn btn-outline-danger m-2">
                                    {Object.keys(walletDetails.lostIncome).map((x) => {
                                      return (<><p className="m-1"><b>{walletDetails.lostIncome[x].toFixed(2)}</b></p>
                                        <small>Donation Lost in <b>{x}</b></small></>)
                                    })}
                                  </button>
                                  : ''}
                              </div>

                            </div>
                          </div>

                          {userData && userData.role_type !== "support" ?
                            planType.purchaseAvailability === 1 ?
                              <div className="col-xl-12 col-lg-12 col-md-12 mb-4 inner-progress-bar-sec">
                                <div className="card dash-card tag-block p-3 box_shadow">

                                  {alertRes !== '' ?
                                    alertRes.length > 0 ? alertRes.map((data, index) => {
                                      return positionAlertFunc(data, index)
                                    }) : '' : ''}

                                  <h2 className="heading-top">Purchase Position</h2>
                                  <div className="warning-sec d-flex align-items-center flex-wrap">
                                    <div className="checkbox-sec m-2">
                                      <label className="container">Number of Position :
                                        {/* <input className="form-control" type="text" name="positionNumber" value={positionNumber} onChange={(e) => setPositionNumber(e.target.value)} disabled={true} /> */}
                                        <Select
                                          menuPlacement="auto"
                                          autoCompelete="off"
                                          value={positionNumber}
                                          name="positionNumber"
                                          placeholder="Number of position"
                                          onChange={(e) => { setPositionNumber(e) }}
                                          options={selectedPosition}
                                        />
                                      </label>
                                    </div>
                                    {!isSpin ?
                                      <button type="button" className="btn btn-new blue-layout m-2" disabled={isSpin} onClick={() => { handlePurchase() }}>Buy</button>
                                      :
                                      <button type="button" className="btn btn-new blue-layout m-2" disabled={isSpin}> <i className="fas fa-spinner fa-spin"></i></button>}
                                  </div>
                                </div>
                              </div>
                              : '' : ''}
                        </div>
                      </div>




                    </div >
                  </div >
                  {/* Banner Scetion */}
                  <div className='col-md-15'>
                    <BannerRandomFooter />
                  </div>
                </div >
              </div >

              <Footer />
            </div >

          </div >
        </div >

      </div >
    </>
  );
}

export default Dashboard