export const bannerTypeConstants = {
    LOGIN_ADS: "LoginAds",
    VIDEO_ADS: "VideoAds",
    BANNER_ADS: "BannersAds",
    TEXT_TEMPLATE1: "TextTemplate1",
    TEXT_TEMPLATE2: "TextTemplate2",
    BANNER_FIXED_HEIGHT: "180",
    BANNER_FIXED_WIDTH: "300",
    LOGIN_ADS_LIMIT: 1,
    HOME_ADS_LIMIT: 7,
    BANNER_TITLE_WORD_LIMIT: '50',
    BANNER_DESCRIPTION_WORD_LIMIT: '250',
    FOOTER_TEXT_ADS_LIMIT: 4,
    FOOTER_BANNER_ADS_LIMIT: 6
}