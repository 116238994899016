import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
// import { BrowserRouter as Router,Route, Switch } from "react-router-dom";
import { Router, Route, Switch } from "react-router-dom";
import { menuActions } from '../actions';
import { apiConstants } from '../constants';
import { history } from '../helpers';
import Login from "../components/Login/Login";
import AuthRoute from "../components/Layout/AuthRoute/AuthRoute";
import RestrictedAuthRoute from "../components/Layout/RestrictedAuthRoute/RestrictedAuthRoute";
import MainLayout from "../components/Layout/MainLayout/MainLayout";
import LoginLayout from "../components/Layout/LoginLayout/LoginLayout";
import Contact from "../components/Contact/Contact";
import MissionVision from "../components/MissionVision/MissionVision";
import Faq from '../components/Faq/Faq';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import './App.css';
import Dashboard from "../components/Dashboard/Dashboard";
import PreRegistration from "../components/PreRegsitration/PreRegistration";
import Profile from '../components/User/Profile';
import ChangePassword from '../components/User/ChangePassword';
import UserListing from '../components/User/UserListing';
import Wallet from '../components/Wallet/Wallet';
import Genealogy from "../components/Genealogy/Genealogy";
import WalletTransactions from "../components/Wallet/WalletTransactions";
import { NomineeUser } from "../components/NomineeUser";
import PositionListing from "../components/Genealogy/PositionListing";
import ReEntryListing from "../components/Genealogy/ReEntryListing";
import WithdrawTransaction from '../components/Wallet/WithdrawTransaction';
import Testimonials from "../components/Testimonials/Testimonials";
import Competition from "../components/Competition/Competition";
import Marketing from "../components/Marketing/Marketing";
import ContentAboutUs from '../components/AboutUs/ContentAboutUs'
import TermsAndCondition from "../components/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "../components/TermsAndCondition/PrivacyPolicy";
import CookiesNotice from "../components/TermsAndCondition/CookiesNotice";
import CookiesPolicy from "../components/TermsAndCondition/CookiesPolicy";
import { NotFound } from "../components/NotFound/NotFound"
import VideoFiles from "../components/VideoFiles/VideoFiles";
import Support from "../components/Support/Support";
import TrackTicket from "../components/Support/TrackTicket";
import Menu from '../components/Menu/Menu';
import Registration from "../components/Registration/Registration";
import Notification from "../components/Notification/Notification";
import NotificationDetails from "../components/Notification/NotificationDetails";
import GlobalPoolUser from "../components/User/GlobalPoolUser";
import InternalMessaging from "../components/Notification/InternalMessaging";

// Advertisment Component
import CreditListing from "../components/Advertisment/CreditListing";
import AddAdvertisment from "../components/Advertisment/AddAdvertisment";
import AdvertismentList from "../components/Advertisment/AdvertismentList";
import PromoTools from "../components/Advertisment/PromoTools";
import AdvertismentReport from "../components/Advertisment/reportAdd";

// Matrix Component
import Network from '../components/Matrix/Network';
import VerificationLink from "../components/PreRegsitration/VerificationLink";
import NextInLine from "../components/NextInLine/nextInLine";
import ShowVideos from "../components/Advertisment/ShowVideos";


const App = () => {
  const [menuTitle, setMenuTitle] = useState([]);
  const poolStatusDetails = sessionStorage.getItem('poolStatusDetails')
  const dispatch = useDispatch();
  const { getMenu } = useSelector(state => {
    return ({
      getMenu: state.getMenu
    })
  });


  useEffect(() => {
    dispatch(menuActions.getMenu(apiConstants.GET_MENU));
    const userDetails = JSON.parse(sessionStorage.getItem('user'));
    history.push({
      state: { detail: userDetails }
    });
  }, [])

  useEffect(() => {
    if (Object.keys(getMenu).length > 0) {
      if (getMenu.getMenu !== undefined) {
        const { data } = getMenu.getMenu;
        setMenuTitle(data.data)
      }
    }
  }, [getMenu])

  return (
    <Router history={history}>
      <Switch >
        {/* <Suspense
          fallback={
            <div className="spin">
             
            </div>
          }
        > */}
        <Route path="/login/:encyptedLink" component={Login}></Route>
        <LoginLayout path="/login" component={Login}></LoginLayout>
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/" component={PreRegistration} />
        {/* <Route exact path="/" component={AdvertismentReport} /> */}
        <Route exact path="/signup" component={PreRegistration} />
        <Route exact path="/verify-email/:email" component={VerificationLink} />
        <Route path="/signup/:ref" component={PreRegistration} />
        <Route exact path="/register" component={Registration} />
        <Route exact path="/register" component={Registration} />
        <Route path="/register/:ref" component={Registration} />
        <Route path="/adverisment-report/:id" component={AdvertismentReport} />

        {/* After login route */}
        <AuthRoute exact path="/dashboard" component={Dashboard} />
        <AuthRoute exact path="/user-listing" component={UserListing} />
        <AuthRoute exact path="/position-listing" component={PositionListing} />
        <AuthRoute exact path="/next-in-line" component={NextInLine} />

        <AuthRoute exact path="/re-entry-listing" component={ReEntryListing} />
        <AuthRoute exact path="/wallet-transaction" component={WalletTransactions} />
        <AuthRoute exact path="/genealogy" component={Genealogy} />
        <AuthRoute exact path="/withdraw-transaction" component={WithdrawTransaction} />
        <AuthRoute exact path="/track-ticket" component={TrackTicket} />
        <AuthRoute exact path="/notification" component={Notification} />
        <AuthRoute exact path="/notification-details" component={NotificationDetails} />
        <AuthRoute exact path="/promo-tools" component={PromoTools} />
        <AuthRoute exact path="/matrix-genealogy" component={Network} />
        
        <RestrictedAuthRoute exact path="/change-password" component={ChangePassword} />
        <RestrictedAuthRoute exact path="/profile" component={Profile} />
        <RestrictedAuthRoute exact path="/nominee-user" component={NomineeUser} />
        <RestrictedAuthRoute exact path="/wallet" component={Wallet} />
        <RestrictedAuthRoute exact path="/testimonials" component={Testimonials} />
        <RestrictedAuthRoute exact path="/support" component={Support} />
        <RestrictedAuthRoute exact path="/message" component={InternalMessaging} />
        {poolStatusDetails && poolStatusDetails === "completed" ?
          <RestrictedAuthRoute exact path="/global-pool" component={GlobalPoolUser} />
          :
          ''
        }
        <RestrictedAuthRoute exact path="/add-advertisment" component={AddAdvertisment} />
        <RestrictedAuthRoute exact path="/advertisment-listing" component={AdvertismentList} />
        <RestrictedAuthRoute exact path="/advertisment-transaction" component={CreditListing} />

        {/* Common general layout */}
        {/* <MainLayout exact path="/about-us" component={AboutUs} /> */}
        <MainLayout exact path="/contact-us" component={Contact} />
        <MainLayout exact path="/faq" component={Faq} />
        <MainLayout exact path="/mission-vision" component={MissionVision} />
        <MainLayout exact path="/content-about-us" component={ContentAboutUs} />
        <MainLayout exact path="/terms-and-condition" component={TermsAndCondition} />
        <MainLayout exact path="/privacy-policy" component={PrivacyPolicy} />
        <MainLayout exact path="/cookies-notice" component={CookiesNotice} />
        <MainLayout exact path="/cookies-policy" component={CookiesPolicy} />
        <MainLayout exact path="/competition" component={Competition} />
        <MainLayout exact path="/marketing" component={Marketing} />
        <MainLayout exact path="/video-files" component={VideoFiles} />
        <AuthRoute exact path="/show-video" component={ShowVideos} />
        {menuTitle && menuTitle.length > 0 ?
          menuTitle.map((x, index) => {
            return (<MainLayout key={index} exact path={`/${x.menuName.toLowerCase()}-page/:menu`} component={Menu} />)
          })
          : ''}

        {menuTitle && menuTitle.length !== 0 ? <Route component={NotFound} /> : ''}

      </Switch>
    </Router>
  );
}
export default App;