import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from "../../actions";
import { apiConstants } from "../../constants";
import { useParams } from "react-router-dom";
import { history } from "../../helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function VerificationLink() {
  const dispatch = useDispatch();
  let { email } = useParams();
  // selector value store here 
  const { is_verify } = useSelector((state) => ({
    is_verify: state.verifyUser,
  }));


  useEffect(() => {
    let obj = {
      "is_verified": 1,
      "email": email,
    };
    dispatch(userActions.emailVerification(`${apiConstants.VERIFY_EMAIL_ADDRESS}/${email}`, obj));
    history.push('/login');
    return () => {
    };
  }, []);

  return (
    <>
    </>
  )
}

export default VerificationLink