import React, { useState, useEffect, useRef } from "react";
import Alert from "react-bootstrap/Alert";

import { apiConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../Common/Dashboard/Header";
import Footer from "../Common/Dashboard/Footer";
import Sidebar from "../Common/Dashboard/Sidebar";
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import Card from "react-bootstrap/Card";
import { alertActions, advertismentAction, walletAction } from "../../actions";
import { bannerTypeConstants } from '../../constants';
import { Modal, Collapse } from 'react-bootstrap';

const Banners = () => {

  const dispatch = useDispatch();
  const [alertRes, setAlertRes] = useState("");
  const [siteConstants, setSiteConstants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState("");
  const [fileErr, setFileErr] = useState('')
  const [walletDetails, setWalletDetails] = useState('');
  const [expandPopUp, setExpandPopUp] = useState(false);
  const [open, setOpen] = useState(false);

  const { LOGIN_ADS,VIDEO_ADS, BANNER_ADS, TEXT_TEMPLATE1, TEXT_TEMPLATE2, BANNER_FIXED_HEIGHT, BANNER_FIXED_WIDTH, BANNER_DESCRIPTION_WORD_LIMIT, BANNER_TITLE_WORD_LIMIT } = bannerTypeConstants;
  const bannerFixedHeight = BANNER_FIXED_HEIGHT;
  const lineDescriptionLimit = 20
  const imgRef = useRef();
  const urlRef = useRef();

  // selector value store here 
  const { alert, language, getWallet, addAdvertisment } = useSelector((state) => ({
    alert: state.alert,
    language: state.language,
    getWallet: state.getWallet,
    addAdvertisment: state.addAdvertisment
  }));

  // Advertisment templates listing
  const typeList = [
    { value: LOGIN_ADS, label: "Login Ads" },
    { value: BANNER_ADS, label: "Banners Ads" },
    { value: TEXT_TEMPLATE1, label: "Text Template1" },
    { value: TEXT_TEMPLATE2, label: "Text Template2" }
  ];

  const formik = useFormik({
    initialValues: {
      bannertitle: "",
      bannerdescription: "description",
      bannerdescriptionLine1: "",
      bannerdescriptionLin2: "",
      bannerdescriptionLine3: "",
      file: "",
      type: LOGIN_ADS,
      credit: "",
      link: "",
      imgUrl: "",
      videoImgUrl: ""
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Please Enter Banner type"),
      bannertitle: Yup.string().required("Please Enter Banner title !!").max(BANNER_TITLE_WORD_LIMIT, `Maximum ${BANNER_TITLE_WORD_LIMIT} allowed !!`),
      bannerdescription: Yup.string()
        .when("type", {
          is: type => (type !== TEXT_TEMPLATE1 && type !== TEXT_TEMPLATE2 ? true : false),
          then: Yup.string().required("Bannner Description required")
            .max(BANNER_DESCRIPTION_WORD_LIMIT, `Maximum ${BANNER_DESCRIPTION_WORD_LIMIT} allowed !!`)
        }),
      bannerdescriptionLine1: Yup.string()
        .when("type", {
          is: type => (type === TEXT_TEMPLATE1 || type === TEXT_TEMPLATE2 ? true : false),
          then: Yup.string().required("First Line required")
        }),
      imgUrl: Yup.string()
        .when("type", {
          is: type => (type === BANNER_ADS && !fileData ? true : false),
          then: Yup.string().required("Upload file image or Image url")
            .matches(
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
              'Enter correct url!'
            )
        }),

      credit: Yup.string().required("Please Enter credit amount"),
      link: Yup.string().matches(
        // /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
        'Enter correct url!'
      )
        .required('Please enter website'),
    }),

    onSubmit: async (values) => {
      debugger
      setFileErr('')
      let bannersDescriptionValue = { 'bannerdescriptionLine1': values.bannerdescription };
      if (values.type === TEXT_TEMPLATE1 || values.type === TEXT_TEMPLATE2) {
        bannersDescriptionValue = {
          'bannerdescriptionLine1': values.bannerdescriptionLine1 ? values.bannerdescriptionLine1 : '',
          'bannerdescriptionLine2': values.bannerdescriptionLine2 ? values.bannerdescriptionLine2 : '',
          'bannerdescriptionLine3': values.bannerdescriptionLine3 ? values.bannerdescriptionLine3 : ''
        }
      }
      let obj = {
        bannertitle: values.bannertitle ? values.bannertitle : 'title',
        bannerdescription: bannersDescriptionValue ? bannersDescriptionValue : 'description',
        status: true,
        files: values.imgUrl ? values.imgUrl : values.videoImgUrl,
        type: values.type,
        credit: values.credit,
        link: values.link
      };

      if ((values.type === BANNER_ADS)) {
        if (formik.values.imgUrl && fileErr === '') {

          obj.files = values.imgUrl;
          setIsLoading(true);
          dispatch(advertismentAction.addAdvertisment(apiConstants.ADD_ADVERTISMENT, obj));
        }
        else if (fileData && !fileErr) {
          setIsLoading(true);
          dispatch(advertismentAction.addAdvertisment(apiConstants.ADD_ADVERTISMENT, obj));
        }
      }
      else if (values.type !== BANNER_ADS) {
        setIsLoading(true);
        dispatch(advertismentAction.addAdvertisment(apiConstants.ADD_ADVERTISMENT, obj));
      }

      if ((values.type === VIDEO_ADS)) {
        if (formik.values.videoImgUrl && fileErr === '') {

          obj.files = values.videoImgUrl;
          setIsLoading(true);
          dispatch(advertismentAction.addAdvertisment(apiConstants.ADD_ADVERTISMENT, obj));
        }
        else if (fileData && !fileErr) {
          setIsLoading(true);
          dispatch(advertismentAction.addAdvertisment(apiConstants.ADD_ADVERTISMENT, obj));
        }
      }
      else if (values.type !== VIDEO_ADS) {
        setIsLoading(true);
        dispatch(advertismentAction.addAdvertisment(apiConstants.ADD_ADVERTISMENT, obj));
      }

      fetchWalletDetails()

    },
  });

  useEffect(() => {
    if (addAdvertisment && isLoading) {
      if (addAdvertisment.addAdvertisment) {
        formik.resetForm();
        setIsLoading(false)
      } else if (addAdvertisment.addAdvertisment === false) {
        setIsLoading(false)
      }
    }
  }, [addAdvertisment])

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language]);

  useEffect(() => {
    if (Object.keys(getWallet).length > 0) {
      if (getWallet.isGetWallet) {
        if (getWallet.getWallet) {
          if (getWallet.getWallet.data !== undefined) {
            setWalletDetails({
              ...walletDetails, 'walletAddress': getWallet.getWallet.data.data.walletAddress,
              walletBalance: getWallet.getWallet.data.data.balance,
              reWithdrawBalance: getWallet.getWallet.data.data.withdraw_balance, 
              withdrawBalance: getWallet.getWallet.data.data.withdrawBalance,
              isWalletCreated: getWallet.getWallet.data.data.isWalletCreated,
              isWalletCondition: getWallet.getWallet.data.data.isWalletCondition,
              loginCredit: getWallet.getWallet.data.data.loginCredit,
              textCredit: getWallet.getWallet.data.data.textCredit,
              bannerCredit: getWallet.getWallet.data.data.bannerCredit,
            })
          }
        }
      }
    }
  }, [getWallet])

  const fetchWalletDetails = () => {
    dispatch(walletAction.getWallet(apiConstants.GET_WALLET));
  }
  // initial call 
  useEffect(() => {
    dispatch(alertActions.clear());
    fetchWalletDetails()
    return () => {
      setAlertRes("");
    };
  }, []);

  // alert handler useeffect
  useEffect(() => {
    if (Object.keys(alert).length > 0) {
      let tempErr = [];

      if (alert.type === "alert-success") {
        tempErr = [{ type: alert.type, message: alert.message }];
      } else if (alert.type === "alert-danger") {
        if (alert.message.errors) {

          if (typeof alert.message.errors === "object" && Object.keys(alert.message.errors).length > 0) {
            let keys = Object.keys(alert.message.errors);
            if (keys.length !== 0) {
              keys.map((data) => {
                tempErr.push({
                  type: alert.type,
                  message: alert.message.errors[data],
                });
              });
            }
          } else {
            tempErr = [{ type: alert.type, message: alert.message.errors }];
          }

        } else {
          tempErr = [{ type: alert.type, message: alert.message }];
        }
      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes("");
      }, 3000);
    }
  }, [alert]);


  return (
    <>
      <div className="main-wrap">
        <Header />
        <div className="dashboard-wraper">
          <Sidebar />
          <div className="content-wrap">
            {alertRes !== undefined && alertRes !== null && alertRes !== ""
              ? alertRes.length > 0
                ? alertRes.map((data, index) => {
                  return (
                    <Alert
                      key={index}
                      variant={
                        data.type === "alert-danger" ? "danger" : "success"
                      }
                      className="alertBox"
                    >
                      {data.message}
                    </Alert>
                  );
                })
                : ""
              : ""}
            <div className="dashboard-content p">
              <div className="dash-body-blk">
                <div className="dash-report-blk px-xl-4 px-2 pt-1"></div>
                <Card>
                  <Card.Body>
                    <Card.Title bg="light"> 
                      Add Advertisment type -- { formik.values.type }
                    </Card.Title>

                    <section className="User-details-sec">
                      <div className="top-use-details">
                        <div className="container">
                          <div className="row d-flex justify-content-center">
                            <div className="col-md-9">
                              <div className="admin-form">
                                <form onSubmit={formik.handleSubmit}>
                                  <div className="form-row justify-content-between">

                                    {/* Banner type radio and preview */}
                                    <div className="form-group col-lg-12 col-md-12">

                                      {/* Banner title */}
                                      <div className="row border p-2">
                                        {/* Section 1 for radio button */}
                                        <div className="col-md-5">
                                          <div onClick={formik.handleChange} className="d-flex flex-column" role="group" aria-labelledby="my-radio-group">

                                            {typeList.map((x, index) => {
                                              return (
                                                <label key={index}>
                                                  <input id={index} aria-controls="example-collapse-text" aria-expanded={open} onClick={() => setOpen(true)} className="m-2" type="radio" name="type" value={x.value} />
                                                  {x.label}
                                                </label>
                                              )
                                            })}
                                            <small className="mt-2 text-info"> <i className="fas fa-info-circle mx-2"></i> Select to add details...</small>
                                          </div>
                                          {/* Show error if banner type not selected */}
                                          <label className="text-danger">
                                            {formik.errors.type &&
                                              formik.touched.type
                                              ? formik.errors.type
                                              : ""}
                                          </label>
                                        </div>

                                      </div>

                                    </div>

                                    <Collapse in={open}>
                                      <div className="w-100" id="example-collapse-text">
                                        {/* Banner Title  */}

                                        <div className="form-group col-lg-12 col-md-12">
                                          <label className="d-flex justify-content-between">
                                            <span>{formik.values.type === LOGIN_ADS ? 'Campaign Name' : formik.values.type === BANNER_ADS ? siteConstants.BANNER_TITILE : 'Subject'}</span>
                                            <small className="f-12 text-danger">{BANNER_TITLE_WORD_LIMIT}</small>
                                          </label>
                                          <input
                                            type="text"
                                            maxLength={BANNER_TITLE_WORD_LIMIT}
                                            className="form-control"
                                            name="bannertitle"
                                            value={formik.values.bannertitle}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          <label className="text-danger">
                                            {formik.errors.bannertitle &&
                                              formik.touched.bannertitle
                                              ? formik.errors.bannertitle
                                              : ""}
                                          </label>
                                        </div>


                                        {formik.values.type === TEXT_TEMPLATE1 || formik.values.type === TEXT_TEMPLATE2 ?
                                          <>
                                            {/* Line Number 1 */}
                                            <div className="form-group col-lg-12 col-md-12">
                                              <label className="d-flex justify-content-between">
                                                <span>Line Number 1</span>
                                                <small className="f-12 text-danger">{lineDescriptionLimit}</small>
                                              </label>
                                              <input
                                                type="text"
                                                max={lineDescriptionLimit}
                                                className="form-control"
                                                name="bannerdescriptionLine1"
                                                value={formik.values.bannerdescriptionLine1}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                              />
                                              <label className="text-danger">
                                                {formik.errors.bannerdescriptionLine1 &&
                                                  formik.touched.bannerdescriptionLine1
                                                  ? formik.errors.bannerdescriptionLine1
                                                  : ""}
                                              </label>
                                            </div>
                                            {/* Line Number 2 */}
                                            <div className="form-group col-lg-12 col-md-12">
                                              <label className="d-flex justify-content-between">
                                                <span>Line Number 2</span>
                                                <small className="f-12 text-danger">{lineDescriptionLimit}</small>
                                              </label>
                                              <input
                                                type="text"
                                                max={lineDescriptionLimit}
                                                className="form-control"
                                                name="bannerdescriptionLine2"
                                                value={formik.values.bannerdescriptionLine2}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                              />

                                            </div>
                                            {/* Line Number 3 */}
                                            <div className="form-group col-lg-12 col-md-12">
                                              <label className="d-flex justify-content-between">
                                                <span>Line Number 3</span>
                                                <small className="f-12 text-danger">{lineDescriptionLimit}</small>
                                              </label>
                                              <input
                                                type="text"
                                                max={lineDescriptionLimit}
                                                className="form-control"
                                                name="bannerdescriptionLine3"
                                                value={formik.values.bannerdescriptionLine3}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                              />

                                            </div>

                                          </>
                                          :
                                          // Bannner Description 
                                          formik.values.type === LOGIN_ADS || formik.values.type === BANNER_ADS ? '' : <div className="form-group col-lg-12 col-md-12">
                                            <label className="d-flex justify-content-between">
                                              <span>{siteConstants.BANNER_DESCRIPTION}</span>
                                              <small className="f-12 text-danger">{BANNER_DESCRIPTION_WORD_LIMIT}</small>
                                            </label>
                                            <input
                                              type="text"
                                              max={BANNER_DESCRIPTION_WORD_LIMIT}
                                              className="form-control"
                                              name="bannerdescription"
                                              value={formik.values.bannerdescription}
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                            />
                                            <label className="text-danger">
                                              {formik.errors.bannerdescription &&
                                                formik.touched.bannerdescription
                                                ? formik.errors.bannerdescription
                                                : ""}
                                            </label>
                                          </div>

                                        }

                                        {/* Website Url Link where advertisment forward user */}
                                        <div className="form-group col-lg-12 col-md-12">
                                          <label>
                                            Website Url
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="link"
                                            value={formik.values.link}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          <label className="text-danger">
                                            {formik.errors.link &&
                                              formik.touched.link
                                              ? formik.errors.link
                                              : ""}
                                          </label>
                                        </div>
                                        

                                        {/* Banner files Upload and Image url */}
                                        {formik.values.type === BANNER_ADS || formik.values.type === LOGIN_ADS ?
                                          <>
                                            <div className="form-group col-lg-12 col-md-12">

                                              <label className="d-flex align-items-center justify-content-between">
                                                <span>Banner Url {formik.values.type === LOGIN_ADS ? <small className="text-danger">(optional)</small> : ''}</span>
                                                {/* <small className="d-block text-danger f-12">max size 50KB  ({"   " + BANNER_FIXED_HEIGHT + ' * ' + BANNER_FIXED_WIDTH}) </small> */}
                                              </label>

                                              <input
                                                type="text"
                                                className="form-control mb-2"
                                                name="imgUrl"
                                                value={formik.values.imgUrl}
                                                onFocus={() => setFileData('')}
                                                onChange={
                                                  formik.handleChange
                                                }
                                                onBlur={formik.handleBlur}
                                              />
                                              <label className="text-danger">
                                                {formik.errors.imgUrl &&
                                                  formik.touched.imgUrl
                                                  ? formik.errors.imgUrl
                                                  : ''}
                                              </label>

                                              {formik.values.imgUrl ?
                                                <img className="invisible" src={formik.values.imgUrl} ref={urlRef} style={{ 'height': 0 + 'px' }} alt="previewbannerImg" /> :
                                                fileData ? <img className="invisible" src={fileData} ref={imgRef} style={{ 'height': 0 + 'px' }} alt="previewbannerImg" /> : ''}
                                              {fileErr ? <p className="text-danger"> {fileErr} </p> : ''}
                                            </div>

                                          </>
                                          : ''}

                                        {/* Add credits for advertisment */}
                                        <div className="form-group col-lg-12 col-md-12">
                                          <label className="d-flex align-items-center justify-content-between">
                                            Assign {siteConstants.CREDIT}
                                            <b className="text-danger f-14 font-bold-weight ml-4">
                                              ( Available Balance &nbsp;
                                              {formik.values.type === LOGIN_ADS && walletDetails.loginCredit ? parseFloat(walletDetails.loginCredit).toFixed(2) : ''}
                                              {(formik.values.type === TEXT_TEMPLATE1 || formik.values.type === TEXT_TEMPLATE2) && walletDetails.textCredit ? parseFloat(walletDetails.textCredit).toFixed(2) : ''}
                                              {formik.values.type === BANNER_ADS && walletDetails.bannerCredit ? parseFloat(walletDetails.bannerCredit).toFixed(2) : ''} )
                                            </b>
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            name="credit"
                                            value={formik.values.credit}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          <label className="text-danger">
                                            {formik.errors.credit &&
                                              formik.touched.credit
                                              ? formik.errors.credit
                                              : ""}
                                          </label>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>

                                  {/* Submit button */}
                                  {!isLoading ? (
                                    <div className="text-center">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Submit
                                      </button>
                                      <button type="button" onClick={() => setExpandPopUp(true)} className="btn btn-danger ml-3">
                                        Preview
                                      </button></div>
                                  ) : (
                                    <>
                                      <div className="text-center">
                                        <button
                                          type="button"
                                          className="btn btn-new blue-layout p-2"
                                          disabled={isLoading}
                                        >
                                          <i className="fas fa-spinner fa-spin f-24"></i>
                                        </button>
                                        <button type="button" onClick={() => setExpandPopUp(true)} className="btn btn-danger ml-3">
                                          Preview
                                        </button>
                                      </div>
                                      {/* <small className="div-center text-danger mt-2">Please wait while file is processing</small> */}
                                    </>
                                  )}

                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Card.Body>
                </Card>
                {/* Banner Scetion */}
                <div className='col-md-12 mt-3'>
                  <BannerRandomFooter />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>

      {/* View Banner by advertisment ID */}
      <Modal size={formik.values.type === LOGIN_ADS ? "lg" : "md"} show={expandPopUp} className="mt-0 text-secondary" animation={true}>
        <Modal.Header style={{ display: "block" }}>
          <div className="d-flex justify-content-between">
            <div>
              <Modal.Title className="text-uppercase">
                {formik.values.bannertitle}
              </Modal.Title>
            </div>
            <div className="notify-cursor">
              <i className='fa fa-times' onClick={() => { setExpandPopUp(false) }}></i>
            </div>
          </div>
        </Modal.Header>
        {
          <Modal.Body className="text-center">

            {formik.values.type === LOGIN_ADS && formik.values.link ?

              formik.values.imgUrl ?
                <div className='card mb-3 text-dark'>
                  <a className="notify-cursor" href={formik.values.link ? formik.values.link : '#'} target="blank">
                    <img className="ad-banner" src={formik.values.imgUrl ? formik.values.imgUrl : "assets/images/dummy-image-square.jpg"} alt="stylebannerImg" />
                  </a>
                </div>
                :
                <div className='card mb-3 text-dark h-60vh'>
                  <iframe className='w-100 h-60vh' src={formik.values.link} title={formik.values.bannertitle ? formik.values.bannertitle : 'Header'} />
                </div>

              :
              <div className="div-center">

                {formik.values.type === BANNER_ADS ?
                  // Banner Ads 
                  <img className="ad-banner" src={fileData ? fileData : formik.values.imgUrl ? formik.values.imgUrl : "assets/images/dummy-image-square.jpg"} alt="stylebannerImg" />
                  :
                  <> {
                    formik.values.type ?
                      <div className={`text-ellipsis card mb-3 ${formik.values.type === TEXT_TEMPLATE1 ? 'text-secondary bg-light' : 'text-dark bg-info'}`} style={{ 'height': bannerFixedHeight + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                        <div className={`text-uppercase text-center text-primary fw-600 ${formik.values.type === TEXT_TEMPLATE1 ? 'border-bottom border-secondary' : 'border-light-secondary'} card-header banners-text-overflow-header`}>{formik.values.bannertitle ? formik.values.bannertitle : 'Header'}</div>
                        <div className="template-card-layout card-body text-center">

                          <small className="card-text banners-text-overflow">
                            {formik.values.bannerdescriptionLine1 ? formik.values.bannerdescriptionLine1 :
                              'Some quick example'}</small>
                          <small className="card-text banners-text-overflow">
                            {formik.values.bannerdescriptionLine2 ? formik.values.bannerdescriptionLine2 :
                              'Some quick example text '}</small>
                          <small className="card-text banners-text-overflow">
                            {formik.values.bannerdescriptionLine3 ? formik.values.bannerdescriptionLine3 :
                              'Some example'}</small>
                        </div>
                        <a className={`text-center g-13 mx-1 ${formik.values.type === TEXT_TEMPLATE1 ? 'text-secondary' : 'text-white'}`} 
                        style={{ textDecoration: 'none' }} data-title={formik.values.link} href={formik.values.link} target="blank">
                          <small className='text-primary fw-600'>Click Here Now </small>
                        </a>
                      </div>
                      : ''
                  }
                  </>
                }
              </div>
            }
          </Modal.Body>
        }
      </Modal>

    </>
  );
};

export default Banners;
